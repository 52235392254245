import styled from "styled-components/native";

export const Container = styled.TouchableOpacity<{ checked: boolean }>`
  border-color: ${(props) =>
    props.checked ? props.theme.colors.primary : "#c2c2c2"};
  border-radius: 4px;
  border-width: 1px;
  margin: 4px;
  padding: 4px 12px;
`;

export const Label = styled.Text<{ checked: boolean }>`
  font-size: 16px;
  color: ${(props) => (props.checked ? props.theme.colors.primary : "#8c8e8d")};
`;
