import { AxiosError } from "axios";

import Service from "../lib/service";
import Midia from "../models/midia.model";
import { obterBlobPelaURI } from "@/utils/blob.util";

export default class MidiaService extends Service<Midia> {
  protected path = "/midias";
  protected Model = Midia;

  async upload(item: Midia, onProgress: (p: number) => void) {
    const formData = new FormData();
    const blob = await obterBlobPelaURI(item.tipo, item.link_midia);
    const dados = {
      nome: item.nome,
      tipo: item.tipo,
      tempo: item.tempo,
      data_vencimento: item.data_vencimento || undefined,
    };

    formData.append("dados", JSON.stringify(dados));
    formData.append("arquivo", blob);

    try {
      const response = await this.api.post<Midia>(this.path, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          onProgress(progressEvent.progress ?? 0);
        },
      });

      return new Midia(response.data);
    } catch (error: AxiosError | any) {
      if (error.isAxiosError && error instanceof AxiosError) {
        console.error(
          "erro upload funcao",
          error.message,
          error.response?.data
        );
      }

      throw error;
    }
  }
}
