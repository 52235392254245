import styled from "styled-components/native";

export const Container = styled.View`
  padding-top: 12px;
`;

export const FiltroItemCheckboxContainer = styled.View`
  flex-direction: row;
  align-items: center;
  margin: 8px -8px;
  padding: 4px;
  flex-wrap: wrap;
`;
