import { FlatList, FlatListProps, Platform } from "react-native";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import DraggableFlatList, {
  DraggableFlatListProps,
} from "react-native-draggable-flatlist";

import { Container } from "./styles";
import { EmptyText, ListSeparator, Loading } from "@/global-styles";

interface Props<T = any> extends FlatListProps<T> {
  emptyText?: string | boolean | null;
  validating?: boolean;
}

interface DraggableListProps<T = any> extends DraggableFlatListProps<T> {
  emptyText?: string | boolean | null;
  validating?: boolean;
}

function List(props: Props) {
  const { refreshing, validating } = props;

  const isLoading = refreshing || (validating && !props.data?.length);
  const showLoading = refreshing !== undefined ? isLoading : undefined;

  if (Platform.OS === "web" && showLoading) {
    return (
      <Container empty={!props.data?.length} isLoading={isLoading}>
        <Loading />
      </Container>
    );
  }

  return (
    <Container empty={!props.data?.length} isLoading={isLoading}>
      <FlatList
        {...props}
        refreshing={showLoading}
        ItemSeparatorComponent={() => <ListSeparator />}
        ListEmptyComponent={
          !showLoading && props.emptyText ? (
            <EmptyText>{props.emptyText}</EmptyText>
          ) : (
            <></>
          )
        }
      />
    </Container>
  );
}

export function DraggableList(props: DraggableListProps) {
  const { refreshing, validating } = props;

  const isLoading = refreshing || (validating && !props.data?.length);
  const showLoading = refreshing !== undefined ? isLoading : undefined;

  if (Platform.OS === "web" && showLoading) {
    return (
      <Container empty={!props.data?.length} isLoading={isLoading}>
        <Loading />
      </Container>
    );
  }

  return (
    <Container empty={!props.data?.length} isLoading={isLoading}>
      <GestureHandlerRootView style={{ flex: 1 }}>
        <DraggableFlatList
          {...props}
          refreshing={showLoading}
          ItemSeparatorComponent={() => <ListSeparator />}
          ListEmptyComponent={
            !showLoading && props.emptyText ? (
              <EmptyText>{props.emptyText}</EmptyText>
            ) : (
              <></>
            )
          }
        />
      </GestureHandlerRootView>
    </Container>
  );
}

export default List;
