import { useMemo } from "react";
import { TouchableOpacity } from "react-native";
import { Container, Label } from "./styles";

interface Props {
  focused: boolean;
  color?: string;
  options?: any;
  route?: any;
  onPress?: () => void;
}

function getRouteLabel(
  route: { key: string; name: string; params: any },
  options: any
) {
  return options.tabBarLabel !== undefined
    ? options.tabBarLabel
    : options.title !== undefined
    ? options.title
    : route.name;
}

function TabBarItem(props: Props) {
  const { route, options, focused, onPress } = props;
  const label = useMemo(() => getRouteLabel(route, options), [route, options]);

  return (
    <TouchableOpacity onPress={onPress}>
      <Container>
        {options.tabBarIcon({ size: 24, focused })}
        <Label focused={focused}>{label}</Label>
      </Container>
    </TouchableOpacity>
  );
}

export default TabBarItem;
