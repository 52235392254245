import styled from "styled-components/native";

export const Container = styled.View`
  flex: 1;
  padding: 14px 14px 14px 24px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-basis: auto;
  background-color: white;
`;

export const Content = styled.View`
  flex: 1;
`;

export const Title = styled.Text`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 2px;
  color: #333;
`;

export const Description = styled.Text`
  font-size: 14px;
  margin-top: 2px;
  color: #888;
`;
