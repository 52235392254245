import Svg from "@components/svg";
import IconButton from "../icon-button";
import { Container } from "./styles";

import MoreIconSvg from "@assets/vectors/list-item/more.svg";

interface Props {
  children?: React.ReactNode;
  onPress?: () => void;
}

function ButtonMore(props: Props) {
  return (
    <Container onPress={props.onPress}>
      <IconButton
        onPress={props.onPress}
        icon={<Svg data={MoreIconSvg} width={16} height={16} />}
      />
    </Container>
  );
}

export default ButtonMore;
