import React from "react";

import Playlist from "@models/playlist.model";
import Svg from "@components/svg";
import Divider from "@components/divider";
import ModalCloseButton from "@components/modal/modal-close-button";
import BottomSheet, { BottomSheetRef } from "@components/bottom-sheet";
import {
  ButtonContainer,
  ButtonItemPlaylist,
  Container,
  LabelButtonItemPlaylist,
} from "./styles";

import EditIconSvg from "@assets/vectors/edit.svg";
import TrashIconSvg from "@assets/vectors/trash.svg";

interface Props {
  onEdit?: () => void;
  onDelete?: () => void;
  playlist?: Playlist;
}

function BottomSheetActionsPlaylist(
  props: Props,
  ref: React.ForwardedRef<BottomSheetRef>
) {
  const bottomSheetRef = ref as React.MutableRefObject<BottomSheetRef>;
  const playlist = props.playlist;

  function handleEdit() {
    props.onEdit?.();
    setTimeout(() => bottomSheetRef.current?.close(), 10);
  }

  function handleDelete() {
    props.onDelete?.();
    setTimeout(() => bottomSheetRef.current?.close(), 10);
  }

  return (
    <BottomSheet
      ref={ref}
      contentContainerStyle={{ padding: 24 }}
      header={{
        title: playlist?.nome,
        subtitle: playlist?.descricao,
        right: (
          <ModalCloseButton onPress={() => bottomSheetRef.current?.close()} />
        ),
      }}
    >
      <Container>
        <Divider style={{ marginBottom: 24 }} />
        <ButtonContainer>
          <ButtonItemPlaylist onPress={handleEdit}>
            <LabelButtonItemPlaylist>Editar</LabelButtonItemPlaylist>
            <Svg data={EditIconSvg} width={24} height={24} />
          </ButtonItemPlaylist>
        </ButtonContainer>
        <Divider style={{ marginVertical: 4 }} />
        <ButtonContainer>
          <ButtonItemPlaylist onPress={handleDelete}>
            <LabelButtonItemPlaylist>Excluir</LabelButtonItemPlaylist>
            <Svg data={TrashIconSvg} width={24} height={24} />
          </ButtonItemPlaylist>
        </ButtonContainer>
      </Container>
    </BottomSheet>
  );
}

export default React.forwardRef(BottomSheetActionsPlaylist);
