import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import Svg from "@components/svg";
import TabBar from "@components/tab-bar";

import Login from "./screens/login";
import Telas from "./screens/telas";
import Midias from "./screens/midias";
import Playlists from "./screens/playlists";
import TelaQrCode from "./screens/tela-qrcode";
import PlaylistMidias from "./screens/playlist-midias";
import CriarMidias from "./screens/criar-midias";
import SelecionarMidias from "./screens/selecionar-midias";
import VisualizarMidia from "./screens/visualizar-midia";
import { AuthProvider } from "./contexts/auth.context";

import MidiasIconSvg from "@assets/vectors/tab-bar-icons/midias.svg";
import MidiasIconActiveSvg from "@assets/vectors/tab-bar-icons/midias-active.svg";
import PlaylistsIconSvg from "@assets/vectors/tab-bar-icons/playlists.svg";
import PlaylistsIconActiveSvg from "@assets/vectors/tab-bar-icons/playlists-active.svg";
import GruposIconSvg from "@assets/vectors/tab-bar-icons/grupos.svg";
import GruposIconActiveSvg from "@assets/vectors/tab-bar-icons/grupos-active.svg";
import TelasIconSvg from "@assets/vectors/tab-bar-icons/telas.svg";
import TelasIconActiveSvg from "@assets/vectors/tab-bar-icons/telas-active.svg";

import BottomSheetFiltros from "./screens/midias/bottom-sheet-filtros";
import ModalExcluirMidia from "./screens/midias/modal-excluir-midia";
import ModalExcluirPlaylist from "./screens/playlists/modal-excluir-playlist";

const Stack = createNativeStackNavigator<RootStackParamList>();
const Tab = createBottomTabNavigator<RootTabParamList>();

const icones = {
  midias: MidiasIconSvg,
  midias_active: MidiasIconActiveSvg,
  playlists: PlaylistsIconSvg,
  playlists_active: PlaylistsIconActiveSvg,
  grupos: GruposIconSvg,
  grupos_active: GruposIconActiveSvg,
  telas: TelasIconSvg,
  telas_active: TelasIconActiveSvg,
};

function TabIcon(icone: string) {
  return (props: { focused: boolean; color: string; size: number }) => {
    const { focused, color, size } = props;

    const iconePath = (icone +
      (focused ? "_active" : "")) as keyof typeof icones;

    return <Svg data={icones[iconePath]} width={size} height={size} />;
  };
}

function BottomTab() {
  return (
    <Tab.Navigator
      screenOptions={{ headerShown: false, title: "Indoortec" }}
      tabBar={(props) => <TabBar {...props} />}
    >
      <Tab.Screen
        name="Telas"
        component={Telas}
        options={{
          tabBarIcon: TabIcon("telas"),
          tabBarLabel: "Telas",
          title: "Indoortec - Telas",
        }}
      />
      <Tab.Screen
        name="Playlists"
        component={Playlists}
        options={{
          tabBarIcon: TabIcon("playlists"),
          tabBarLabel: "Playlists",
          title: "Indoortec - Playlists",
        }}
      />
      <Tab.Screen
        name="Midias"
        component={Midias}
        initialParams={{ tipo: undefined, periodo: undefined }}
        options={{
          tabBarIcon: TabIcon("midias"),
          tabBarLabel: "Mídias",
          title: "Indoortec - Repositório",
        }}
      />
    </Tab.Navigator>
  );
}

const screensConfig: any = {
  initialRouteName: "BottomTab",
  screens: {
    Login: "login",
    BottomTab: {
      screens: {
        Telas: "telas",
        Playlists: "playlists",
        Midias: "midias",
      },
    },
    PlaylistMidias: "playlists/:id",
    SelecionarMidias: "playlists/:id/selecionar-midias",
    VisualizarMidia: "playlists/:id/visualizar-midia",
    TelaQrCode: "telas/qr-code",
    CriarMidias: "midias/criar",
    BottomSheetFiltros: "midias/filtros",
    ModalExcluirMidia: "midias/excluir",
    ModalExcluirPlaylist: "playlists/excluir",
  },
};

const linking = {
  prefixes: ["https://gestor.indoortec.com.br"],
  config: screensConfig,
};

export default function Routes() {
  return (
    <NavigationContainer linking={linking}>
      <AuthProvider>
        <Stack.Navigator
          screenOptions={{
            headerShown: false,
            navigationBarColor: "transparent",
          }}
        >
          <Stack.Group screenOptions={{ animation: "fade" }}>
            <Stack.Screen name="BottomTab" component={BottomTab} />
            <Stack.Screen name="Login" component={Login} />
          </Stack.Group>
          <Stack.Group screenOptions={{ animation: "slide_from_right" }}>
            <Stack.Screen
              name="PlaylistMidias"
              component={PlaylistMidias}
              options={{ title: "Indoortec - Mídias da Playlist" }}
            />
          </Stack.Group>
          <Stack.Group
            screenOptions={{
              presentation: "modal",
              animation: "slide_from_bottom",
            }}
          >
            <Stack.Screen name="TelaQrCode" component={TelaQrCode} />
            <Stack.Screen name="CriarMidias" component={CriarMidias} />
            <Stack.Screen
              name="SelecionarMidias"
              component={SelecionarMidias}
              options={{ title: "Indoortec - Selecionar mídias" }}
            />
          </Stack.Group>
          <Stack.Group
            screenOptions={{
              presentation: "transparentModal",
              animation: "none",
            }}
          >
            <Stack.Screen
              name="BottomSheetFiltros"
              component={BottomSheetFiltros}
            />
            <Stack.Screen
              name="ModalExcluirMidia"
              component={ModalExcluirMidia}
            />
            <Stack.Screen
              name="ModalExcluirPlaylist"
              component={ModalExcluirPlaylist}
            />
            <Stack.Screen
              name="VisualizarMidia"
              component={VisualizarMidia}
              options={{
                animation: "fade_from_bottom",
                contentStyle: {
                  backgroundColor: "rgba(0,0,0,0.5)",
                },
              }}
            />
          </Stack.Group>
        </Stack.Navigator>
      </AuthProvider>
    </NavigationContainer>
  );
}
