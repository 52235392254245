import { useEffect, useRef } from "react";

import { AuthContextType, useAuth } from "../contexts/auth.context";
import { IService } from "../lib/service";

export function useService<T extends IService>(
  service: new (auth: AuthContextType) => T
): T {
  const auth = useAuth();
  const serviceRef = useRef<T>(new service(auth));

  useEffect(() => {
    if (serviceRef.current && auth.token) {
      serviceRef.current.setAuth(auth);
    }
  }, [auth]);

  return serviceRef.current;
}
