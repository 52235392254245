import styled from "styled-components/native";

export const Container = styled.View`
  background-color: #fff;
  border-radius: 24px;
  padding: 24px;
`;

export const Content = styled.View`
  margin-top: 16px;
`;

export const Header = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
`;

export const Title = styled.Text`
  font-size: 18px;
  font-weight: 500;
`;
