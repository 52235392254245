import Midia from "@models/midia.model";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Container } from "./styles";

interface Props {
  onPress?: () => void;
  children: React.ReactNode;
}

function ItemMidiaContainer(props: Props) {
  if (!props.onPress) {
    return <Container>{props.children}</Container>;
  }

  return (
    <TouchableOpacity onPress={props.onPress}>
      <Container>{props.children}</Container>
    </TouchableOpacity>
  );
}

export default ItemMidiaContainer;
