import React from "react";
import { Controller, useForm } from "react-hook-form";

import Divider from "@components/divider";
import Button from "@components/button";
import FieldText from "@components/field-text";
import ModalCloseButton from "@components/modal/modal-close-button";
import BottomSheet, { BottomSheetRef } from "@components/bottom-sheet";

import Playlist from "@/models/playlist.model";
import PlaylistService from "@services/playlist.service";
import { useService } from "@hooks/use-service";

import { Text, Row } from "@/global-styles";
import { Container } from "./styles";

import EditIconSvg from "@assets/vectors/edit.svg";
import EditIconActiveSvg from "@assets/vectors/edit-active.svg";

interface Props {
  playlist?: Playlist;
  onSuccess: () => void;
}

function BottomSheetSalvarPlaylist(
  props: Props,
  ref: React.ForwardedRef<BottomSheetRef>
) {
  const sheetRef = ref as React.MutableRefObject<BottomSheetRef>;
  const playlistService = useService(PlaylistService);
  const [loading, setLoading] = React.useState(false);

  const form = useForm<Playlist>({
    defaultValues: props.playlist || { nome: "", descricao: "" },
    resetOptions: {
      keepValues: false,
    },
  });

  React.useEffect(() => {
    props.playlist ? form.reset(props.playlist) : form.reset();
  }, [props.playlist]);

  function clear() {
    form.reset({ nome: "", descricao: "" });
  }

  async function salvarPlaylist(playlist: Playlist) {
    setLoading(true);

    try {
      const data = {
        _id: props.playlist?._id,
        nome: playlist.nome?.trim(),
        descricao: playlist.descricao?.trim(),
        itens: props.playlist?.itens.map((item) => item._id) || [],
      } as unknown as Playlist;

      await playlistService.upsert(data);

      sheetRef.current?.close();
      form.reset();
      props.onSuccess();
    } catch (error: any) {
      if (error.isAxiosError) {
        console.log("axiosError", error.response?.data);
      } else {
        console.log("error", error);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <BottomSheet
      ref={ref}
      contentContainerStyle={{ padding: 24 }}
      onClose={clear}
      header={{
        title: "Informações da playlist",
        right: <ModalCloseButton onPress={() => sheetRef.current?.close()} />,
      }}
    >
      <Divider />
      <Container>
        <Text size={16} style={{ marginBottom: 16 }}>
          Adicione um nome e uma descrição para vincular a lista de reprodução.
        </Text>
        <Row>
          <Controller
            name="nome"
            control={form.control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <FieldText
                label="Inserir nome"
                placeholder="Nome da playlist"
                onChangeText={onChange}
                onBlur={onBlur}
                value={value}
                icon={({ focused }) =>
                  focused ? EditIconActiveSvg : EditIconSvg
                }
              />
            )}
          />
        </Row>
        <Row>
          <Controller
            name="descricao"
            control={form.control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <FieldText
                label="Inserir descrição"
                placeholder="Descrição da playlist"
                onChangeText={onChange}
                onBlur={onBlur}
                value={value}
                icon={({ focused }) =>
                  focused ? EditIconActiveSvg : EditIconSvg
                }
              />
            )}
          />
        </Row>
        <Button
          text="Confirmar"
          loading={loading}
          disabled={!form.formState.isDirty}
          onPress={form.handleSubmit(salvarPlaylist)}
        />
      </Container>
    </BottomSheet>
  );
}

export default React.forwardRef(BottomSheetSalvarPlaylist);
