import { useState } from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import { TouchableOpacity } from "react-native";

import Svg from "@components/svg";
import { Container, Content, Input } from "./styles";

import CloseButtonDarkSvg from "@assets/vectors/close-button-dark.svg";

interface Props {}

function HeaderBusca(props: Props) {
  const route = useRoute<SearchableRouteProp>();
  const navigation = useNavigation<SearchNavigationProp>();
  const [value, setValue] = useState(route.params?.search || "");

  function handleClose() {
    setValue("");
    navigation.setParams({ search: undefined });
  }

  function handleSearch() {
    navigation.setParams({ search: value });
  }

  return (
    <Container>
      <Content>
        <Input
          value={value}
          blurOnSubmit={false}
          onChangeText={setValue}
          onSubmitEditing={handleSearch}
          placeholder="Buscar"
          returnKeyType="search"
          autoComplete="off"
          autoFocus
        />
        <TouchableOpacity onPress={handleClose}>
          <Svg
            width={32}
            height={32}
            data={CloseButtonDarkSvg}
            onPress={handleClose}
          />
        </TouchableOpacity>
      </Content>
    </Container>
  );
}

export default HeaderBusca;
