import React from "react";
import { Video, ResizeMode } from "expo-av";

import { Container } from "./styles";

interface Props {
  uri: string;
}

function VisualizarMidiaVideo(props: Props) {
  const video = React.useRef<Video>(null);

  return (
    <Container>
      <Video
        ref={video}
        style={{ flex: 1 }}
        videoStyle={{ flex: 1, width: "100%", height: "100%" }}
        source={{ uri: props.uri }}
        onLoad={() => video.current?.playAsync()}
        resizeMode={ResizeMode.CONTAIN}
        useNativeControls
      />
    </Container>
  );
}

export default VisualizarMidiaVideo;
