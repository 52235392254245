import React from "react";
import { TouchableOpacity } from "react-native";

import Playlist from "@models/playlist.model";
import ButtonMore from "@components/button-more";
import { filtrarMidiasVencidas } from "@/utils/filtro-midias.util";

import { Container, Content, Description, Title } from "./styles";

interface Props {
  onOptions: () => void;
  onPress: () => void;
  item: Playlist;
}

function ItemPlaylist(props: Props) {
  const { nome, descricao, itens } = props.item;

  const quantidade = React.useMemo(() => {
    return filtrarMidiasVencidas(itens).length;
  }, [itens]);

  return (
    <TouchableOpacity onPress={props.onPress}>
      <Container>
        <Content>
          <Title>{nome}</Title>
          <Description>{descricao}</Description>
          <Description>{quantidade} mídias</Description>
        </Content>
        <ButtonMore onPress={props.onOptions} />
      </Container>
    </TouchableOpacity>
  );
}

export default ItemPlaylist;
