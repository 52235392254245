import { useState, useRef, useEffect } from "react";
import { Pressable, View } from "react-native";
import { AxiosError } from "axios";

import Svg from "@components/svg";
import Button from "@components/button";
import FieldText, { FieldTextRef } from "@components/field-text";
import Screen from "@components/screen";
import AuthService from "@services/auth.service";
import { useService } from "@hooks/use-service";

import {
  Container,
  Content,
  Error,
  Hello,
  Info,
  Row,
  Title,
  TopBar,
  Welcome,
} from "./styles";

import EmailIconSvg from "@assets/vectors/login/email.svg";
import EmailActiveIconSvg from "@assets/vectors/login/email-active.svg";
import LockIconSvg from "@assets/vectors/login/lock.svg";
import LockActiveIconSvg from "@assets/vectors/login/lock-active.svg";
import EyeIconSvg from "@assets/vectors/login/eye.svg";

interface Props extends StackScreenProps<"Login"> {}

function Login(props: Props) {
  const authService = useService(AuthService);

  const { navigation } = props;
  const emailRef = useRef<FieldTextRef>(null);
  const passwordRef = useRef<FieldTextRef>(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (error) setError("");
  }, [email, password]);

  function toggleShowPassword() {
    setShowPassword((prev) => !prev);
  }

  async function onLogin() {
    setLoading(true);

    try {
      const response = await authService.login(email, password);

      if (response?.success) {
        navigation.replace("BottomTab");
      }
    } catch (error) {
      const axiosError = error as AxiosError<{ message: string }>;
      setError(axiosError.response?.data.message || "Erro ao fazer login");
    } finally {
      setLoading(false);
    }
  }

  return (
    <Screen
      hasHeader={false}
      style={{ backgroundColor: "white" }}
      contentContainerStyle={{
        padding: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container>
        <TopBar>
          <Hello>Olá,</Hello>
          <Welcome>Bem-vindo ao seu gestor de telas!</Welcome>
        </TopBar>
        <Content>
          <Title>Faça o seu login</Title>
          {!!error && <Error>{error}</Error>}
          <Row>
            <FieldText
              ref={emailRef}
              placeholder="E-mail"
              label="E-mail"
              keyboardType="email-address"
              autoComplete="email"
              autoCapitalize="none"
              returnKeyType="next"
              onSubmitEditing={() => passwordRef.current?.focus()}
              value={email}
              onChangeText={setEmail}
              icon={({ focused }) =>
                focused ? EmailActiveIconSvg : EmailIconSvg
              }
            />
          </Row>
          <Row>
            <FieldText
              ref={passwordRef}
              placeholder="Senha"
              label="Senha"
              autoCapitalize="none"
              autoComplete="password"
              secureTextEntry={!showPassword}
              value={password}
              onSubmitEditing={onLogin}
              onChangeText={setPassword}
              icon={({ focused }) =>
                focused ? LockActiveIconSvg : LockIconSvg
              }
              right={
                <View style={{ marginRight: 10, borderRadius: 24 }}>
                  <Pressable
                    style={{ padding: 8, borderRadius: 24 }}
                    android_ripple={{ borderless: true }}
                    onPress={toggleShowPassword}
                  >
                    <Svg data={EyeIconSvg} width={24} height={24} />
                  </Pressable>
                </View>
              }
            />
          </Row>
          <Button
            text="Entrar"
            onPress={onLogin}
            disabled={!email || !password || loading}
            loading={loading}
          />
        </Content>
        <Info>Para entrar você precisa estar{"\n"}conectado a internet.</Info>
      </Container>
    </Screen>
  );
}

export default Login;
