import { DateTime } from "luxon";

import Midia from "@models/midia.model";
import { Content, Description, Title } from "./styles";

interface Props {
  midia: Midia;
  progress?: number;
}

function ItemMidiaContainer(props: Props) {
  const { midia } = props;

  const tipoMidia = midia.tipo === "video" ? "Vídeo" : "Imagem";
  const dataMidia = DateTime.fromISO(midia.data_criacao).toLocaleString(
    DateTime.DATE_FULL
  );

  return (
    <Content>
      <Title>{midia.nome}</Title>
      <Description>
        {tipoMidia} • {midia.pendente ? progresso(props.progress) : dataMidia}
      </Description>
    </Content>
  );
}

function progresso(progress?: number) {
  if (progress === 1) return "Processando...";
  return "Pendente... " + Math.round((progress ?? 0) * 100) + "%";
}

export default ItemMidiaContainer;
