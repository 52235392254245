import styled from "styled-components/native";

export const Container = styled.View`
  background-color: white;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
`;

export const Content = styled.View`
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
`;

export const Title = styled.Text`
  font-size: 18px;
  font-weight: 500;
`;

export const Description = styled.Text`
  color: #888;
`;

export const MidiaCount = styled.Text`
  color: ${(props) => props.theme.colors.primary};
  position: absolute;
  right: 0;
`;

export const ImageContainer = styled.View``;

export const StatusDot = styled.View<{ active: boolean }>`
  position: absolute;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${(props) => (props.active ? "#15AA2C" : "red")};
`;
