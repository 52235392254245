import Midia from "./midia.model";

export default class Playlist implements Model {
  _id: string;
  nome: string;
  descricao: string;
  itens: Midia[];
  data_criacao: string;
  data_atualizacao: string;

  constructor(playlist: Playlist) {
    this._id = playlist._id;
    this.nome = playlist.nome;
    this.descricao = playlist.descricao;
    this.itens = playlist.itens;
    this.data_criacao = playlist.data_criacao;
    this.data_atualizacao = playlist.data_atualizacao;
  }
}
