import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import { SvgProps } from "react-native-svg";
import { Picker } from "@react-native-picker/picker";

import { Container, Label } from "./styles";

interface ItemProps {
  label: string;
  value: string;
}

interface Props {
  label: string;

  value: string;
  onChange: (itemValue: string, itemIndex: number) => void;
  items: ItemProps[];

  style?: StyleProp<ViewStyle>;
  icon?: (props: IconProps) => React.FC<SvgProps>;
  right?: React.ReactNode;
  children?: React.ReactNode;
}

interface IconProps {
  focused: boolean;
}

function FieldSelect(props: Props) {
  const { icon, label, style, right, ...rest } = props;
  // const inputRef = React.useRef<TextInput>(ref as any);

  const [focused, setFocused] = React.useState(false);

  const Icon = React.useMemo(() => {
    if (props.icon) {
      return props.icon?.({ focused: focused });
    }
  }, [focused]);

  return (
    <Container>
      <Label>{label}</Label>
      {Icon && <Icon style={{ marginLeft: 18 }} width={18} height={18} />}
      <Picker
        style={{ flex: 1 }}
        selectedValue={rest.value}
        onValueChange={rest.onChange}
      >
        {rest.items.map((item, index) => (
          <Picker.Item key={index} label={item.label} value={item.value} />
        ))}
      </Picker>
    </Container>
  );
}

export default FieldSelect;
