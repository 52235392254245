import React from "react";
import { StatusBar } from "expo-status-bar";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useTheme } from "styled-components/native";

import { Loading } from "@components/button/styles";
import BackButton from "./back-button";
import {
  Container,
  ContainerProps,
  Content,
  ContentProps,
  TopBar,
  TopBarActionsLeft,
  TopBarActionsRight,
  TopBarContainer,
  TopBarTitle,
} from "./styles";

interface Props {
  header?: React.ReactNode;
  left?: React.ReactNode;
  right?: React.ReactNode;
  back?: boolean;
  title?: string;
  style?: ContainerProps["style"];
  hasHeader?: boolean;
  loading?: boolean;
  children?: React.ReactNode;
  contentContainerStyle?: ContentProps["style"];
}

function Screen(props: Props) {
  const { title, back, style, left, right, header, hasHeader = true } = props;
  const insets = useSafeAreaInsets();
  const theme = useTheme();

  return (
    <Container style={style} insets={insets}>
      <StatusBar style="light" backgroundColor={theme.colors.primary} />
      {hasHeader && (
        <TopBarContainer insets={insets}>
          {header || (
            <TopBar>
              {(left || back) && (
                <TopBarActionsLeft>
                  {back && <BackButton />}
                  {left}
                </TopBarActionsLeft>
              )}
              <TopBarTitle>{title}</TopBarTitle>
              <TopBarActionsRight>{right}</TopBarActionsRight>
            </TopBar>
          )}
        </TopBarContainer>
      )}
      {props.loading ? (
        <Loading />
      ) : (
        <Content
          style={props.contentContainerStyle}
          children={props.children}
        />
      )}
    </Container>
  );
}

export default Screen;
