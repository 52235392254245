import React, { useImperativeHandle } from "react";
import ReactNativeModal from "react-native-modal";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Dimensions } from "react-native";

import Divider from "../divider";
import ModalCloseButton from "./modal-close-button";
import { useIsDesktop } from "@hooks/use-media-query";

import { Container, Content, Header, Title } from "./styles";

interface Props {
  title?: string;
  children: React.ReactNode;
  onClose?: () => void;
}

export interface ModalRef {
  open: () => void;
  close: () => void;
  isVisible: () => boolean;
}

const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

function Modal(props: Props, ref: React.Ref<ModalRef>) {
  const { title } = props;
  const insets = useSafeAreaInsets();

  const [visible, setVisible] = React.useState(false);
  const isDesktop = useIsDesktop();

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
    isVisible: () => visible,
  }));

  function handleClose() {
    setVisible(false);
  }

  function handleOpen() {
    setVisible(true);
  }

  return (
    <ReactNativeModal
      isVisible={visible}
      deviceWidth={screenWidth + insets.left + insets.right}
      deviceHeight={screenHeight + insets.top + insets.bottom}
      onModalHide={props.onClose}
      statusBarTranslucent
      useNativeDriverForBackdrop
      useNativeDriver
      style={isDesktop ? { width: 400, alignSelf: "center" } : {}}
    >
      <Container>
        <Header>
          <Title>{title}</Title>
          <ModalCloseButton onPress={handleClose} />
        </Header>
        <Divider />
        <Content>{props.children}</Content>
      </Container>
    </ReactNativeModal>
  );
}

export default React.forwardRef(Modal);
