import styled from "styled-components/native";

interface ContainerProps {
  focused: boolean;
}

export const Container = styled.View<ContainerProps>`
  border-radius: 12px;
  border-color: ${(props) =>
    props.focused ? props.theme.colors.primary : "#e2e3e4"};
  border-width: 1.4px;
  margin-top: 10px;
  flex-direction: row;
  align-items: center;
  overflow: visible;
`;

export const Content = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const Label = styled.Text`
  background-color: #fff;
  position: absolute;
  color: #686777;
  left: 12px;
  top: -12px;
  padding: 0 8px;
`;
