interface Props {
  data: any;
  width?: number;
  height?: number;
  opacity?: number;
  style?: any;
  onPress?: () => void;
}

function Svg(props: Props) {
  const { data, style, onPress, ...innerProps } = props;
  return (
    <img src={data} style={{ ...style, ...innerProps }} onClick={onPress} />
  );
}

export default Svg;
