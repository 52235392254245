import { SvgProps } from "react-native-svg";

import Svg from "@components/svg";
import IconButton from "@components/icon-button";

import { ActionContainer } from "./styles";

interface Props {
  onPress?: () => void;
  icon: React.FC<SvgProps>;
  width?: number;
  height?: number;
}

function ItemMidiaAction(props: Props) {
  const { width = 16, height = 16 } = props;

  return (
    <ActionContainer onPress={props.onPress}>
      <IconButton
        onPress={props.onPress}
        icon={<Svg data={props.icon} width={width} height={height} />}
      />
    </ActionContainer>
  );
}

export default ItemMidiaAction;
