import styledWeb from "styled-components";
import styled from "styled-components/native";

export const Container = styledWeb.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #000;
  
  @media (min-width: 768px) and (orientation: landscape) {
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 8vh;
    margin-bottom: 8vh;
    border-radius: 8px;
    overflow: hidden;
  }
  
  @media (min-width: 1280px) and (orientation: landscape) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1024px;
    width: 1024px;
  }
`;

export const Content = styled.View`
  flex: 1;
`;
