import BackButton from "@components/screen/back-button";
import { Container, Title } from "./styles";

import CloseIconSvg from "@assets/vectors/close-button.svg";

interface Props {
  titulo: string;
}

function VisualizarMidiaHeader(props: Props) {
  return (
    <Container>
      <BackButton icon={CloseIconSvg} />
      <Title>{props.titulo}</Title>
    </Container>
  );
}

export default VisualizarMidiaHeader;
