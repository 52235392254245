import React from "react";
import { useIsFocused } from "@react-navigation/native";
import { Camera, CameraType } from "expo-camera";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { BarCodeScanner } from "expo-barcode-scanner";

import Screen from "@components/screen";
import BackButton from "@components/screen/back-button";
import RequestPermissions from "./request-permissions";
import { Container, InfoText, ScannerCamera } from "./styles";

import CloseIconSvg from "@assets/vectors/close-button.svg";

interface Props extends StackScreenProps<"TelaQrCode"> {}

function TelaQrCode(props: Props) {
  const { navigation } = props;

  const insets = useSafeAreaInsets();
  const isFocused = useIsFocused();
  const cameraRef = React.useRef<Camera>(null);

  const [permission] = Camera.useCameraPermissions();
  const [scanned, setScanned] = React.useState(false);

  if (!isFocused || !permission) return null;
  if (!permission.granted) {
    return <RequestPermissions />;
  }

  function handleBarCodeScanned({ type, data }: any) {
    setScanned(true);
    cameraRef.current?.pausePreview();

    navigation.navigate({
      name: "Telas",
      merge: true,
      params: { scanned: true, data },
    });
  }

  return (
    <Screen
      title="Cadastrar tela"
      contentContainerStyle={{ padding: 0 }}
      left={<BackButton icon={CloseIconSvg} />}
    >
      <ScannerCamera
        ref={cameraRef}
        insets={insets}
        type={CameraType.back}
        ratio="16:9"
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
        barCodeScannerSettings={{
          barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
        }}
      >
        <Container>
          <InfoText>
            O QR Code será detectado automaticamente quando você o posicionar
            entre as linhas-guia
          </InfoText>
        </Container>
      </ScannerCamera>
    </Screen>
  );
}

export default TelaQrCode;
