import { BottomTabBarProps } from "@react-navigation/bottom-tabs";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { View } from "react-native";
import { Container } from "./styles";
import TabBarItem from "./tab-bar-item";

interface Props extends BottomTabBarProps {}

function TabBar(props: Props) {
  const { state, descriptors, navigation } = props;

  const insets = useSafeAreaInsets();

  function jumpTo(routeName: keyof RootTabParamList) {
    navigation.dispatch({
      type: "JUMP_TO",
      payload: { name: routeName },
    });
  }

  return (
    <View style={{ elevation: 2, backgroundColor: "white" }}>
      <View style={{ height: 1, width: "100%", backgroundColor: "#e8e8e8" }} />
      <Container insets={insets}>
        {state.routes.map((route: any, index: number) => {
          const routeKey = route.key;
          const isFocused = state.index === index;
          const { options } = descriptors[routeKey];
          return (
            <TabBarItem
              key={routeKey}
              focused={isFocused}
              color={isFocused ? "#673ab7" : "#686777"}
              options={options}
              route={route}
              onPress={() => jumpTo(route.name)}
            />
          );
        })}
      </Container>
    </View>
  );
}

export default TabBar;
