import React from "react";
import { View } from "react-native";

import Midia from "@models/midia.model";
import Svg from "@components/svg";
import Divider from "@components/divider";
import ItemMidia from "@components/item-midia";
import BottomSheet, { BottomSheetRef } from "@components/bottom-sheet";
import {
  ButtonContainer,
  ButtonItemMidia,
  Container,
  LabelButtonItemMidia,
} from "./styles";

import EditIconSvg from "@assets/vectors/edit.svg";
import TrashIconSvg from "@assets/vectors/trash.svg";

interface Props {
  onEdit?: () => void;
  onDelete?: () => void;
  midia?: Midia;
}

function BottomSheetActionsMidia(
  props: Props,
  ref: React.ForwardedRef<BottomSheetRef>
) {
  const bottomSheetRef = ref as React.MutableRefObject<BottomSheetRef>;
  const midia = props.midia;

  function handleEdit() {
    props.onEdit?.();
    setTimeout(() => bottomSheetRef.current?.close(), 10);
  }

  function handleDelete() {
    props.onDelete?.();
    setTimeout(() => bottomSheetRef.current?.close(), 10);
  }

  return (
    <BottomSheet
      ref={ref}
      hasHeader={false}
      contentContainerStyle={{ padding: 12 }}
    >
      <Container>
        {midia && (
          <ItemMidia.Container>
            <ItemMidia.Image midia={midia} />
            <ItemMidia.Content midia={midia} />
          </ItemMidia.Container>
        )}
        <View
          style={{
            width: "100%",
            height: 0.5,
            backgroundColor: "#e8e8e8",
            marginBottom: 24,
          }}
        />
        <ButtonContainer>
          <ButtonItemMidia onPress={handleEdit}>
            <LabelButtonItemMidia>Editar</LabelButtonItemMidia>
            <Svg data={EditIconSvg} width={24} height={24} />
          </ButtonItemMidia>
        </ButtonContainer>
        <Divider style={{ marginVertical: 4 }} />
        <ButtonContainer>
          <ButtonItemMidia onPress={handleDelete}>
            <LabelButtonItemMidia>Excluir</LabelButtonItemMidia>
            <Svg data={TrashIconSvg} width={24} height={24} />
          </ButtonItemMidia>
        </ButtonContainer>
      </Container>
    </BottomSheet>
  );
}

export default React.forwardRef(BottomSheetActionsMidia);
