import styled from "styled-components/native";
import styledWeb from "styled-components";

export const ListContainer = styledWeb.div<{ desktop?: boolean }>`
  position: absolute;
  right: 24px;
  bottom: 24px;

  @media (min-width: 768px) and (orientation: landscape) {
    right: 10vw;
  }

  @media (min-width: 1280px) and (orientation: landscape) {
    right: calc(calc(100vw - 1024px) / 2);
  }
`;

export const ItemContainer = styled.TouchableOpacity`
  padding: 16px;
  border-radius: 32px;
  background-color: ${(props) => props.theme.colors.primary};
  margin-top: 16px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
`;

export const DisabledBackdrop = styled.View`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 32px;
  background-color: #fff8;
`;

export const Loading = styled.ActivityIndicator`
  position: absolute;
  align-self: center;
  top: 0;
  bottom: 0;
`;
