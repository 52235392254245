import { useEffect, useRef, useState } from "react";
import { Alert, Platform } from "react-native";
import { DragEndParams } from "react-native-draggable-flatlist";
import useSWR, { mutate } from "swr";

import FAB from "@components/fab";
import Button from "@components/button";
import Screen from "@components/screen";
import List from "./list";

import Midia from "@models/midia.model";
import PlaylistService from "@services/playlist.service";
import { useService } from "@hooks/use-service";
import { useIsDesktop } from "@hooks/use-media-query";
import { filtrarMidiasVencidas } from "@/utils/filtro-midias.util";

import PlusIconSvg from "@assets/vectors/plus.svg";
import SaveIconSvg from "@assets/vectors/save.svg";

interface Props extends StackScreenProps<"PlaylistMidias"> {}

function PlaylistMidias(props: Props) {
  const { id, nome } = props.route.params;
  const { navigation } = props;

  const isDesktop = useIsDesktop();
  const playlistService = useService(PlaylistService);
  const { data, isLoading, isValidating } = useSWR("/playlists/" + id, () =>
    playlistService.buscar(id, { params: { populate: "itens" } })
  );

  const midiasRef = useRef<Midia[]>([]);
  const [midias, setMidias] = useState<Midia[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data && !isLoading && !midias.length) {
      const midias = filtrarMidiasVencidas(data.itens);
      midiasRef.current = midias;
      setMidias(midias);
    }
  }, [data, isLoading]);

  async function onDragEnd(data: DragEndParams<Midia>) {
    midiasRef.current = data.data;
    setMidias(data.data);
  }

  async function onSave() {
    setLoading(true);

    try {
      const playlist = await playlistService.atualizarMidiasPlaylist(
        id,
        midiasRef.current.map((midia) => midia._id)
      );

      await Promise.all([
        mutate("/playlists"),
        mutate("/telas"),
        mutate("/playlists/" + id, playlist),
      ]);

      navigation.goBack();
    } catch (error) {
      console.error("erro ao salvar itens", error);
    } finally {
      setLoading(false);
    }
  }

  function onSelecionarMidias() {
    navigation.navigate("SelecionarMidias", {
      id,
      itens: midiasRef.current.map((item) => item._id) || [],
      onGoBack(itens) {
        const midiasUpdate = [...midias, ...itens];
        midiasRef.current = midiasUpdate;
        setMidias(midiasUpdate);
      },
    });
  }

  function onDeleteWeb(midia: Midia) {
    if (
      window.confirm(
        `Deseja realmente remover a mídia "${midia.nome}" dessa playlist?`
      )
    ) {
      removerMidia(midia);
    }
  }

  function onDeleteNative(midia: Midia) {
    Alert.alert(
      "Remover mídia",
      `Deseja realmente remover a mídia "${midia.nome}" dessa playlist?`,
      [
        { text: "Cancelar", style: "cancel" },
        {
          text: "Remover",
          style: "destructive",
          onPress: () => removerMidia(midia),
        },
      ]
    );
  }

  function onDelete(midia: Midia) {
    Platform.select({
      web: () => onDeleteWeb(midia),
      default: () => onDeleteNative(midia),
    })();
  }

  function removerMidia(midia: Midia) {
    const midiasUpdate = midiasRef.current.filter(
      (item) => item._id !== midia._id
    );

    midiasRef.current = midiasUpdate;
    setMidias(midiasUpdate);
  }

  return (
    <Screen
      back
      title={data?.nome || nome}
      style={{ paddingBottom: 0 }}
      contentContainerStyle={{ padding: 0 }}
      right={
        isDesktop && (
          <Button
            small
            text="Salvar"
            onPress={onSave}
            loading={loading}
            color="onPrimary"
          />
        )
      }
    >
      <List
        itens={midias}
        refreshing={isLoading}
        validating={isValidating}
        onDragEnd={onDragEnd}
        onDelete={onDelete}
      />
      <FAB.List>
        <FAB.Item
          icon={PlusIconSvg}
          onPress={onSelecionarMidias}
          disabled={loading}
        />
        {isDesktop || (
          <FAB.Item icon={SaveIconSvg} onPress={onSave} loading={loading} />
        )}
      </FAB.List>
    </Screen>
  );
}

export default PlaylistMidias;
