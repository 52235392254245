import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) and (orientation: landscape) {
    flex-direction: row;
    height: 100vh;
  }
`;

export const TopBar = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  padding: 28px;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) and (orientation: landscape) {
    flex: 1;
    justify-content: center;
    padding: 0 10vw;
  }
`;

export const Content = styled.div`
  flex: 1;
  padding: 36px;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) and (orientation: landscape) {
    justify-content: center;
    padding: 0 10vw;
  }
`;

export const Hello = styled.span`
  font-size: 24px;
  margin-bottom: 16px;
  font-weight: bold;
  color: #fff;

  @media (min-width: 768px) and (orientation: landscape) {
    font-size: 32px;
  }
`;

export const Welcome = styled.span`
  font-weight: 300;
  font-size: 28px;
  color: #fff;
`;

export const Title = styled.span`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
`;

export const Row = styled.div`
  margin-bottom: 24px;
`;

export const Info = styled.pre`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #888;
  padding: 0 16px;

  @media (min-width: 768px) and (orientation: landscape) {
    display: none;
  }
`;

export const Error = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: #f00;
  margin-bottom: 16px;
`;
