import styled from "styled-components";

export const Container = styled.div<{ empty?: boolean; isLoading?: boolean }>`
  flex: 1;
  overflow-y: auto;
  height: 100%;
  max-height: calc(
    100vh -
      ${(props) => props.theme.sizes.topBar + props.theme.sizes.bottomBar}px
  );

  @media (min-width: 768px) and (orientation: landscape) {
    overflow: visible;
    background-color: ${(props) =>
      props.isLoading || props.empty
        ? props.theme.colors.background
        : "transparent"};

    position: fixed;
    top: 90px;
    left: 0;
    right: 0;
    bottom: ${(props) => props.theme.sizes.bottomBar}px;
    padding: 0 10vw;
    height: calc(
      100vh -
        ${(props) => props.theme.sizes.topBar + props.theme.sizes.bottomBar}px
    ) !important;

    & > div {
      overflow-y: auto;
      max-height: calc(
        100vh -
          ${(props) => (props.isLoading || props.empty ? "90px" : "150px")} -
          ${(props) => props.theme.sizes.topBar + props.theme.sizes.bottomBar}px
      );
    }
  }

  @media (min-width: 768px) and (orientation: landscape) {
    align-self: center;

    & > div {
      border-radius: 10px;
      background-color: ${(props) =>
        props.isLoading || props.empty ? "transparent" : "white"};
      box-shadow: 0px 2px 5px
        ${(props) =>
          props.isLoading || props.empty
            ? "transparent"
            : "rgba(0, 0, 0, 0.12)"};
      z-index: 2;
    }
  }

  @media (min-width: 1280px) and (orientation: landscape) {
    margin: auto;
    padding: 0 calc((100vw - 1024px) / 2);
    top: 110px;
    left: 0;
    right: 0;
    bottom: ${(props) => props.theme.sizes.bottomBar}px;

    & > div {
      max-width: 1024px;
    }
  }
`;
