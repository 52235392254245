import React, { useEffect, useImperativeHandle, useState } from "react";
import { useIsFocused } from "@react-navigation/native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Dimensions, KeyboardAvoidingView, View } from "react-native";
import Modal from "react-native-modal";

import { useIsDesktop } from "@hooks/use-media-query";
import { Container, Header, Pill, Title } from "./styles";
import { Text } from "@/global-styles";

interface Props {
  onClose?: () => void;
  onWillClose?: () => void;
  onOpen?: () => void;
  children: React.ReactNode;
  header?: {
    left?: React.ReactNode;
    right?: React.ReactNode;
    title?: string;
    subtitle?: string;
    titleAlign?: "left" | "center" | "right";
    showPill?: boolean;
  };
  hasHeader?: boolean;
  hasBackdrop?: boolean;
  closeOnBackButtonPress?: boolean;
  closeOnBackdropPress?: boolean;
  closeOnDragDown?: boolean;
  contentContainerStyle?: any;
}

export interface BottomSheetRef {
  open: () => void;
  close: () => void;
  isVisible: () => boolean;
}

const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

function BottomSheet(props: Props, ref: React.Ref<any>) {
  const modalRef = React.useRef<Modal>(null);
  const insets = useSafeAreaInsets();
  const isFocused = useIsFocused();

  const [visible, setVisible] = useState(false);
  const isDesktop = useIsDesktop();

  const {
    header,
    children,
    hasHeader = true,
    closeOnBackButtonPress = true,
    closeOnBackdropPress = true,
    closeOnDragDown = true,
    onOpen,
    onClose,
  } = props;

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
    isVisible: () => visible,
  }));

  useEffect(() => {
    if (!isFocused && visible) handleClose();
  }, [isFocused]);

  function handleClose() {
    setVisible(false);
  }

  function handleOpen() {
    setVisible(true);
  }

  return (
    <Modal
      ref={modalRef}
      isVisible={visible}
      deviceWidth={screenWidth + insets.left + insets.right}
      deviceHeight={screenHeight + insets.top + insets.bottom}
      onBackButtonPress={closeOnBackButtonPress ? handleClose : undefined}
      onBackdropPress={closeOnBackdropPress ? handleClose : undefined}
      onSwipeComplete={closeOnDragDown ? handleClose : undefined}
      swipeDirection={closeOnDragDown ? "down" : undefined}
      hasBackdrop={props.hasBackdrop ?? true}
      useNativeDriverForBackdrop
      statusBarTranslucent
      onShow={onOpen}
      onModalHide={onClose}
      onModalWillHide={props.onWillClose}
      style={
        isDesktop
          ? { width: 400, alignSelf: "center" }
          : { justifyContent: "flex-end", margin: 0 }
      }
    >
      <KeyboardAvoidingView behavior="padding">
        <>
          <Pill />
          <Container style={props.contentContainerStyle}>
            {hasHeader && (
              <Header>
                <View style={{ flexShrink: 0, alignItems: "flex-start" }}>
                  {header?.left}
                </View>
                <View style={{ flex: 1, flexDirection: "column" }}>
                  {header?.title && (
                    <Title style={{ textAlign: header?.titleAlign }}>
                      {header?.title}
                    </Title>
                  )}
                  {header?.subtitle && (
                    <Text style={{ textAlign: header?.titleAlign }}>
                      {header?.subtitle}
                    </Text>
                  )}
                </View>
                <View style={{ flexShrink: 0, alignItems: "flex-end" }}>
                  {header?.right}
                </View>
              </Header>
            )}
            {children}
          </Container>
        </>
      </KeyboardAvoidingView>
    </Modal>
  );
}

export default React.forwardRef(BottomSheet);
