import Service from "../lib/service";
import Tela from "../models/tela.model";

type TelaCreate = Omit<Partial<Tela>, "playlist"> & {
  token: string;
  playlist: string;
};

export default class TelaService extends Service<Tela> {
  protected path = "/telas";
  protected Model = Tela;

  public async criarTela(tela: TelaCreate) {
    return this.criar(tela as unknown as Tela);
  }
}
