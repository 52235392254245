import styled, { DefaultTheme } from "styled-components/native";
import { Platform, TextProps as ReactTextProps } from "react-native";

interface TextProps extends ReactTextProps {
  size?: number;
  color?: keyof DefaultTheme["colors"];
}

export const Row = styled.View`
  margin-bottom: 24px;
`;

export const Text = styled.Text<TextProps>`
  font-size: ${(props) => props.size || 14}px;
  color: ${(props) =>
    props.color ? props.theme.colors[props.color] : "#8c8e8d"};
`;

export const EmptyText = styled.Text`
  font-size: 20px;
  color: #121212;
  text-align: center;
  padding: 24px;
`;

export const ListSeparator = styled.View`
  height: ${Platform.OS === "web" ? 1 : 0.4}px;
  background-color: ${Platform.OS === "web" ? "#f0f0f0" : "#c2c2c2"};
`;

export const Loading = styled.ActivityIndicator.attrs((props) => ({
  color: props.theme.colors.primary,
  size: 22,
}))<{ small?: boolean }>`
  padding: ${(props) => (props.small ? "8px 24px" : "24px")};
`;
