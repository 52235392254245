import { EdgeInsets } from "react-native-safe-area-context";
import styled from "styled-components";

interface ContainerProps {
  insets: EdgeInsets;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  padding-bottom: ${({ insets }) => insets.bottom}px;

  @media (min-width: 768px) and (orientation: landscape) {
    gap: 24px;
    justify-content: center;
  }
`;
