import styled, { DefaultTheme } from "styled-components/native";

function textColor(theme: DefaultTheme, color: ContainerProps["color"]) {
  const capitalized = color.charAt(0).toUpperCase() + color.slice(1);
  const text = ("on" + capitalized) as ContainerProps["color"];

  return theme.colors[text];
}

export interface ContainerProps extends DisableableProps {
  color: keyof DefaultTheme["colors"];
  small?: boolean;
}

interface DisableableProps {
  disabled?: boolean;
}

export const Container = styled.View<ContainerProps>`
  background-color: ${(props) =>
    props.disabled
      ? props.theme.colors.disabled
      : props.theme.colors[props.color]};
  align-items: center;
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
`;

export const Touchable = styled.TouchableOpacity`
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

export const Text = styled.Text<ContainerProps>`
  color: ${(props) =>
    textColor(props.theme, props.disabled ? "disabled" : props.color)};
  font-size: 16px;
  line-height: 22px;
  padding: ${(props) => (props.small ? "8px 18px" : "18px")};
`;

export const Loading = styled.ActivityIndicator.attrs((props) => ({
  color: props.theme.colors.primary,
  size: 22,
}))<{ small?: boolean }>`
  padding: ${(props) => (props.small ? "8px 18px" : "18px")};
`;
