import React from "react";
import useSWR from "swr";

import List from "@components/list";
import Screen from "@components/screen";
import Button from "@components/button";
import BackButton from "@components/screen/back-button";
import Midia from "@models/midia.model";
import MidiaService from "@services/midia.service";
import { useService } from "@hooks/use-service";
import { useIsDesktop } from "@hooks/use-media-query";

import ItemSelecionarMidias from "./item-selecionar-midias";
import { SaveButtonContainer } from "./styles";

import CloseIconSvg from "@assets/vectors/close-button.svg";

interface Props extends StackScreenProps<"SelecionarMidias"> {}

function SelecionarMidias(props: Props) {
  const navigation = props.navigation;
  const itens = props.route.params.itens;

  const isDesktop = useIsDesktop();
  const midiaService = useService(MidiaService);
  const fetcher = () => {
    return midiaService.listar({
      params: {
        filtro: {
          $or: [
            { data_vencimento: { $exists: false } },
            { data_vencimento: { $gte: new Date() } },
          ],
        },
      },
    });
  };

  const { data, isLoading, isValidating } = useSWR("/midias", fetcher);
  const [idsSelecionados, setIdsSelecionados] = React.useState<string[]>();
  const [midias, setMidias] = React.useState<Midia[]>();

  React.useEffect(() => {
    if (data?.length) {
      setMidias(data.filter((midia) => !itens?.includes(midia._id)));
    }
  }, [data]);

  function onSelecionarMidia(midia: Midia, selecionado: boolean) {
    if (selecionado) {
      setIdsSelecionados([...(idsSelecionados || []), midia._id]);
    } else {
      setIdsSelecionados(idsSelecionados?.filter((id) => id !== midia._id));
    }
  }

  async function handleSalvarPlaylist() {
    props.route.params.onGoBack(
      data?.filter((midia) => idsSelecionados?.includes(midia._id)) || []
    );

    navigation.goBack();
  }

  return (
    <Screen
      title="Selecionar mídias"
      contentContainerStyle={{ padding: 0 }}
      left={<BackButton icon={CloseIconSvg} />}
      right={
        isDesktop && (
          <Button
            small
            text="Selecionar"
            onPress={handleSalvarPlaylist}
            disabled={!midias?.length}
            color="onPrimary"
          />
        )
      }
    >
      <List
        data={midias}
        refreshing={isLoading}
        validating={isValidating}
        extraData={idsSelecionados}
        emptyText="Adicione uma mídia primeiro para poder selecionar"
        renderItem={({ item }) => (
          <ItemSelecionarMidias
            item={item}
            key={item._id}
            selecionado={idsSelecionados?.includes(item._id) || false}
            onSelecionar={(selecionado) => onSelecionarMidia(item, selecionado)}
          />
        )}
      />
      <SaveButtonContainer>
        <Button
          text="Selecionar mídias"
          onPress={handleSalvarPlaylist}
          disabled={!midias?.length}
        />
      </SaveButtonContainer>
    </Screen>
  );
}

export default SelecionarMidias;
