import Service from "../lib/service";
import Playlist from "../models/playlist.model";

export default class PlaylistService extends Service<Playlist> {
  protected path = "/playlists";
  protected Model = Playlist;

  async atualizarMidiasPlaylist(id: string, midias: string[]) {
    const { data } = await this.api.put(`/playlists/${id}`, {
      itens: midias,
    });

    return data;
  }
}
