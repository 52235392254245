import { ThemeProvider } from "styled-components/native";
import { ThemeProvider as ThemeProviderWeb } from "styled-components";
import { LogBox } from "react-native";
import { Settings } from "luxon";
import { enableExperimentalWebImplementation } from "react-native-gesture-handler";
import "setimmediate";

import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";

import "./src/styles.css";

import Routes from "./src/routes";
import theme from "./src/theme";

enableExperimentalWebImplementation(true);

Settings.defaultLocale = "pt-BR";
LogBox.ignoreLogs([
  "Non-serializable values were found in the navigation state.",
]);

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <ThemeProviderWeb theme={theme}>
        <Routes />
      </ThemeProviderWeb>
    </ThemeProvider>
  );
}

serviceWorkerRegistration.register();
