import { TouchableOpacity } from "react-native";

import Svg from "@components/svg";
import { Container } from "./styles";

import CloseIconSvg from "@assets/vectors/close.svg";

interface Props {
  onPress?: () => void;
}

function ModalCloseButton(props: Props) {
  return (
    <Container>
      <TouchableOpacity onPress={props.onPress} hitSlop={16}>
        <Svg data={CloseIconSvg} width={36} height={36} />
      </TouchableOpacity>
    </Container>
  );
}

export default ModalCloseButton;
