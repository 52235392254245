import { SvgProps } from "react-native-svg";

import Svg from "@components/svg";
import {
  DisabledBackdrop,
  ItemContainer,
  ListContainer,
  Loading,
} from "./styles";

namespace FAB {
  export interface ItemProps {
    onPress?: () => void;
    icon: React.FC<SvgProps>;
    disabled?: boolean;
    loading?: boolean;
  }

  export function Item(props: ItemProps) {
    const IconSvg = props.icon;
    const disabled = props.disabled || props.loading;
    return (
      <ItemContainer onPress={props.onPress} disabled={disabled}>
        <Svg
          data={IconSvg}
          width={24}
          height={24}
          opacity={props.loading ? 0 : 1}
        />
        {disabled && <DisabledBackdrop />}
        {props.loading && <Loading color="#fff" />}
      </ItemContainer>
    );
  }

  export interface ListProps {
    children?: React.ReactNode;
  }

  export function List(props: ListProps) {
    return <ListContainer>{props.children}</ListContainer>;
  }
}

export default FAB;
