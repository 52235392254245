import styled from "styled-components/native";

export const Container = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const TextInfo = styled.Text`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
`;

export const RequestPermissionsButton = styled.TouchableOpacity`
  background-color: ${(props) => props.theme.colors.primary};
  padding: 8px 16px;
  border-radius: 24px;
  margin-top: 24px;
`;

export const RequestPermissionsButtonText = styled.Text`
  font-size: 18px;
  font-weight: 500;
  color: #fff;
`;
