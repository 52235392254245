export default class Midia implements Model {
  _id: string;
  nome: string;
  tipo: "image" | "video";
  data_criacao: string;
  data_vencimento?: string;
  data_inicio?: string;
  link_midia?: string;
  link_miniatura?: string;
  tempo?: number;

  constructor(midia: Midia, public pendente?: boolean) {
    this._id = midia._id;
    this.nome = midia.nome;
    this.tipo = midia.tipo;
    this.data_criacao = midia.data_criacao;
    this.link_midia = midia.link_midia;
    this.link_miniatura = midia.link_miniatura;
    this.data_vencimento = midia.data_vencimento;
    this.data_inicio = midia.data_inicio;
    this.tempo = midia.tempo;
  }
}
