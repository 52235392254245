import React, { useRef } from "react";
import { useSWRConfig } from "swr";

import Button from "@components/button";
import Modal, { ModalRef } from "@components/modal";
import MidiaService from "@services/midia.service";
import { useService } from "@hooks/use-service";
import { Row, Text } from "@/global-styles";
import Midia from "@models/midia.model";

interface Props extends StackScreenProps<"ModalExcluirMidia"> {}
type FetcherResponse = Record<"midias" | "pendentes", Midia[]>;

function ModalExcluirMidia(props: Props) {
  const { navigation } = props;

  const midia = props.route.params?.midia;
  const modalRef = useRef<ModalRef>(null);
  const midiaService = useService(MidiaService);

  const { mutate } = useSWRConfig();
  const [excluindo, setExcluindo] = React.useState(false);

  React.useLayoutEffect(() => {
    modalRef.current?.open();
  }, []);

  function closeModal() {
    navigation.goBack();
  }

  async function excluirMidia() {
    if (!midia) return;

    try {
      setExcluindo(true);

      await mutate(["/midias", ""], midiaService.excluir(midia._id), {
        revalidate: false,
        populateCache(result: any, current?: FetcherResponse) {
          console.log(result);
          return {
            midias: current?.midias?.filter((m) => m._id !== midia._id) || [],
            pendentes: current?.pendentes || [],
          };
        },
      });

      modalRef.current?.close();
    } catch (error: any) {
      if (error.isAxiosError) {
        console.log("axiosError", error.response?.data);
      } else {
        console.log("error", error);
      }
    } finally {
      setExcluindo(false);
    }
  }

  return (
    <Modal ref={modalRef} title="Tem certeza disso?" onClose={closeModal}>
      <Row>
        <Text>
          Você está prestes a remover a midia{" "}
          <Text style={{ color: "#554AF0" }}>{midia?.nome}</Text>. Clique em
          confirmar para remover a midia da playlist.
        </Text>
      </Row>
      <Row>
        <Button
          color="secondary"
          text="Cancelar"
          onPress={() => modalRef.current?.close()}
          disabled={excluindo}
        />
      </Row>
      <Button
        color="primary"
        text="Confirmar"
        onPress={excluirMidia}
        loading={excluindo}
      />
    </Modal>
  );
}

export default ModalExcluirMidia;
