import styled from "styled-components/native";

export const Container = styled.View`
  background-color: white;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
`;

export const Content = styled.View`
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
`;

export const Title = styled.Text`
  font-size: 18px;
  font-weight: 500;
`;

export const Description = styled.Text`
  /* font-size: 14px; */
  color: #888;
`;

export const ImageContainer = styled.TouchableOpacity`
  width: 60px;
  height: 60px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const ProgressContainer = styled.View`
  position: absolute;
  background-color: #554af088;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.Image`
  width: 60px;
  height: 60px;
  border-radius: 12px;
`;

export const ActionContainer = styled.TouchableOpacity`
  padding: 8px 16px;
  padding-left: 32px;
  height: 100%;
  align-items: center;
  flex-direction: row;
`;
