import Midia from "@models/midia.model";
import { DateTime } from "luxon";

export function filtrarMidiasVencidas(midias: Midia[]): Midia[] {
  return midias.filter((item) => {
    return !item.data_vencimento || new Date(item.data_vencimento) > new Date();
  });
}

export function gerarFiltroTipo(tipo: string) {
  if (tipo === "all") return undefined;
  return tipo;
}

export function gerarFiltroPeriodo(periodo: string) {
  switch (periodo) {
    case "today":
      return {
        $gte: DateTime.local().startOf("day").toISO(),
        $lte: DateTime.local().endOf("day").toISO(),
      };
    case "yesterday":
      return {
        $gte: DateTime.local().minus({ day: 1 }).startOf("day").toISO(),
        $lte: DateTime.local().minus({ day: 1 }).endOf("day").toISO(),
      };
    case "past_month":
      return {
        $gte: DateTime.local().minus({ month: 1 }).startOf("month").toISO(),
        $lte: DateTime.local().minus({ month: 1 }).endOf("month").toISO(),
      };
    case "current_month":
      return {
        $gte: DateTime.local().startOf("month").toISO(),
        $lte: DateTime.local().endOf("month").toISO(),
      };
    default:
      return undefined;
  }
}
