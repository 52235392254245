import React, { useLayoutEffect } from "react";
import { useIsFocused } from "@react-navigation/native";
import useSWR from "swr";

import ItemPlaylist from "./item-playlist";
import BottomSheetSalvarPlaylist from "./bottom-sheet-salvar-playlist";
import PlaylistService from "@services/playlist.service";

import FAB from "@components/fab";
import List from "@components/list";
import Screen from "@components/screen";
import { BottomSheetRef } from "@components/bottom-sheet";
import { useService } from "@hooks/use-service";

import PlusIconSvg from "@assets/vectors/plus.svg";
import BottomSheetActionsPlaylist from "./bottom-sheet-actions-playlist";
import Playlist from "@models/playlist.model";

interface Props extends TabScreenProps<"Playlists"> {}

function Playlists(props: Props) {
  const { navigation } = props;

  const bottomSheetRef = React.useRef<BottomSheetRef>(null);
  const bottomSheetActionsRef = React.useRef<BottomSheetRef>(null);
  const playlistService = useService(PlaylistService);

  const [playlistSelecionada, setPlaylistSelecionada] =
    React.useState<Playlist>();

  const focused = useIsFocused();
  const fetcher = () => playlistService.listar();

  const { data, mutate, isLoading, isValidating } = useSWR(
    "/playlists",
    fetcher
  );

  useLayoutEffect(() => {
    if (focused) mutate();
  }, [focused]);

  function onPressOptions(playlist: Playlist) {
    setPlaylistSelecionada(playlist);
    bottomSheetActionsRef.current?.open();
  }

  async function listarPlaylists() {
    setPlaylistSelecionada(undefined);
    await mutate();
  }

  function criarPlaylist() {
    setPlaylistSelecionada(undefined);
    bottomSheetRef.current?.open();
  }

  const updateKey = React.useMemo(() => {
    return data?.map((item) => item._id).join("");
  }, [data]);

  return (
    <Screen
      title="Playlists"
      style={{ paddingBottom: 0 }}
      contentContainerStyle={{ padding: 0 }}
    >
      <List
        key={updateKey}
        data={data}
        refreshing={isLoading}
        validating={isValidating}
        onRefresh={() => mutate()}
        emptyText="Nenhuma playlist encontrada"
        renderItem={({ item }) => (
          <ItemPlaylist
            key={`${item._id}:${item.data_atualizacao}`}
            item={item}
            onOptions={() => onPressOptions(item)}
            onPress={() =>
              navigation.navigate("PlaylistMidias", {
                id: item._id,
                nome: item.nome,
              })
            }
          />
        )}
      />
      <FAB.List>
        <FAB.Item icon={PlusIconSvg} onPress={criarPlaylist} />
      </FAB.List>
      <BottomSheetSalvarPlaylist
        ref={bottomSheetRef}
        playlist={playlistSelecionada}
        onSuccess={listarPlaylists}
      />
      <BottomSheetActionsPlaylist
        ref={bottomSheetActionsRef}
        playlist={playlistSelecionada}
        onEdit={() => {
          bottomSheetRef.current?.open();
        }}
        onDelete={() => {
          navigation.navigate("ModalExcluirPlaylist", {
            playlist: playlistSelecionada as Playlist,
          });
        }}
      />
    </Screen>
  );
}

export default Playlists;
