import Svg from "@components/svg";
import { Container, ActionButton } from "./styles";

import SearchIconSvg from "@assets/vectors/search.svg";
import FilterIconSvg from "@assets/vectors/filter.svg";

interface Props {
  onSearch: () => void;
  onFilter: () => void;
}

function HeaderActions(props: Props) {
  return (
    <Container>
      <ActionButton onPress={props.onSearch}>
        <Svg data={SearchIconSvg} width={22} height={22} />
      </ActionButton>
      <ActionButton onPress={props.onFilter}>
        <Svg data={FilterIconSvg} width={22} height={22} />
      </ActionButton>
    </Container>
  );
}

export default HeaderActions;
