import styled from "styled-components";
import Modal from "react-native-modal";

export const StyledModal = styled(Modal)``;

export const Container = styled.div`
  background-color: #fff;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;

  @media (min-width: 768px) and (orientation: landscape) {
    border-radius: 24px;
  }
`;

export const Pill = styled.div`
  background-color: white;
  width: 100px;
  height: 5px;
  margin-bottom: 14px;
  border-radius: 8px;
  align-self: center;

  @media (min-width: 768px) and (orientation: landscape) {
    display: none;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: #000;
`;
