import { ActivityIndicator } from "react-native";
import ImageViewer from "react-native-image-zoom-viewer";

import { Container } from "./styles";

interface Props {
  uri: string;
}

function VisualizarMidiaImage(props: Props) {
  return (
    <Container>
      <ImageViewer
        useNativeDriver
        imageUrls={[{ url: props.uri }]}
        renderIndicator={() => <></>}
        loadingRender={() => <ActivityIndicator size="large" color="#fff" />}
      />
    </Container>
  );
}

export default VisualizarMidiaImage;
