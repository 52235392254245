import Midia from "./midia.model";
import Playlist from "./playlist.model";

export default class Tela implements Model {
  _id: string;
  nome: string;
  descricao: string;
  mac: string;
  data_conexao: string;
  playlist?: Playlist;
  ativa?: boolean;

  midia_atual?: Midia;
  status?: string;

  constructor(tela: Tela) {
    this._id = tela._id;
    this.mac = tela.mac;
    this.nome = tela.nome;
    this.descricao = tela.descricao;
    this.data_conexao = tela.data_conexao;
  }
}
