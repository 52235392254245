import { DateTime } from "luxon";
import React, { SyntheticEvent, createElement } from "react";

interface Props extends HTMLInputElement {
  value: string;
  onChangeText: (value: string) => void;
  onBlur: (e: any) => void;
  onFocus: (e: any) => void;
}

interface DateTimeInputRef {
  focus: () => void;
}

function DateTimeInput(props: Props, ref: React.Ref<DateTimeInputRef>) {
  const inputRef = React.useRef<HTMLInputElement>();

  React.useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current?.focus();
      inputRef.current?.showPicker();
    },
  }));

  function handleFocus(e: any) {
    props.onFocus?.(e);
    inputRef.current?.showPicker();
  }

  function handleBlur(e: any) {
    props.onBlur?.(e);
  }

  const date = React.useMemo(() => {
    if (props.value && DateTime.fromISO(props.value).isValid) {
      return DateTime.fromISO(props.value).toFormat("yyyy-MM-dd'T'HH:mm");
    }

    return "";
  }, [props.value]);

  return createElement("input", {
    ref: inputRef,
    type: "datetime-local",
    value: date,
    style: {
      width: "100%",
      border: "none",
      margin: 4,
      padding: 12,
      fontSize: 16,
      backgroundColor: "transparent",
    },
    placeholder: props.placeholder,
    onBlur: handleBlur,
    onFocus: handleFocus,
    onInput: (event: SyntheticEvent<HTMLInputElement>) => {
      const target = event.target as HTMLInputElement;
      const date = new Date(target?.value);
      props.onChangeText(date.toISOString());
    },
  });
}

export default React.forwardRef(DateTimeInput);
