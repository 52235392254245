import { Platform, View } from "react-native";

interface Props {
  style?: any;
}

function Divider(props: Props) {
  return (
    <View
      style={{
        width: "100%",
        height: Platform.OS === "web" ? 1 : 0.5,
        backgroundColor: Platform.OS === "web" ? "#f0f0f0" : "#d1d1d1",
        marginVertical: 2,
        ...props.style,
      }}
    />
  );
}

export default Divider;
