import styledNative from "styled-components/native";
import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  padding: 12px;
  height: 64px;

  @media (min-width: 768px) and (orientation: landscape) {
    height: 90px;
    padding-left: 10vw;
    padding-right: 10vw;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-radius: 4px;
  padding: 0 8px;

  @media (min-width: 768px) and (orientation: landscape) {
    margin: auto;
    max-width: 1024px;
    margin-top: 13px;
  }
`;

export const Input = styledNative.TextInput`
  flex: 1;
  font-size: 16px;
  padding: 8px;
`;
