import React from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useNavigation } from "@react-navigation/native";
import api from "@/api";

export interface AuthContextType {
  token: string;
  loading: boolean;
  setToken(token: string): void;
  login(token: string): Promise<void>;
  logout(): Promise<void>;
}

type Navigation = NativeStackNavigationProp<RootStackParamList>;

export const AuthContext = React.createContext<AuthContextType>({
  token: "",
  loading: true,
  setToken() {},
  async login(token: string) {},
  async logout() {},
});

export function useAuth() {
  return React.useContext(AuthContext);
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const navigation = useNavigation<Navigation>();

  const [loading, setLoading] = React.useState(true);
  const [token, setToken] = React.useState("");

  async function getToken() {
    const token = await AsyncStorage.getItem("@token");
    if (token) setToken(token);
    setLoading(false);
  }

  async function login(token: string) {
    await AsyncStorage.setItem("@token", token);
    setToken(token);
  }

  async function logout() {
    await AsyncStorage.setItem("@token", "");
    setToken("");
  }

  React.useEffect(() => {
    getToken();
  }, []);

  React.useEffect(() => {
    const res = api.interceptors.response.use(
      (resp) => resp,
      (error) => {
        if (error.response?.status === 401) {
          logout();
          navigation.reset({ routes: [{ name: "Login" }] });
        }
      }
    );

    return () => {
      api.interceptors.response.eject(res);
    };
  }, []);

  if (loading) return <></>;

  return (
    <AuthContext.Provider value={{ token, loading, setToken, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
