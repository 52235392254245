import styled from "styled-components/native";
import ExpoCheckBox from "expo-checkbox";

export const Container = styled.TouchableOpacity`
  background-color: white;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  padding-right: 24px;
`;

export const Content = styled.View`
  flex: 1;
  padding-left: 16px;
`;

export const Image = styled.Image`
  width: 60px;
  height: 60px;
  border-radius: 12px;
`;

export const Title = styled.Text`
  font-size: 18px;
  font-weight: 500;
`;

export const CheckBox = styled(ExpoCheckBox).attrs((props) => ({
  color: props.value ? props.theme.colors.primary : "gray",
}))``;
