import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
`;

export const SaveButtonContainer = styled.div`
  padding: 16px;
  padding-top: 0;

  @media (min-width: 768px) and (orientation: landscape) {
    display: none;
  }
`;
