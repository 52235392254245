import { TouchableOpacity, TouchableOpacityProps } from "react-native";

interface Props extends TouchableOpacityProps {
  icon: React.ReactNode;
}

function IconButton(props: Props) {
  return (
    <TouchableOpacity
      style={{ alignItems: "center", justifyContent: "center" }}
      onPress={props.onPress}
    >
      {props.icon}
    </TouchableOpacity>
  );
}

export default IconButton;
