import api from "../api";
import Service from "../lib/service";

interface ApiResponse {
  success: boolean;
  message: string;
}

interface LoginResponse extends ApiResponse {
  token: string;
}

export default class AuthService extends Service<any> {
  protected path = "/auth";
  protected Model = Object;

  async login(email: string, senha: string) {
    const response = await api.post<LoginResponse>("/auth/login", {
      email,
      senha,
    });

    if (response.status === 200) {
      await this.auth?.login(response.data.token);
      return response.data;
    }
  }
}
