import styled from "styled-components/native";

export const Container = styled.View``;

export const ButtonContainer = styled.View`
  border-radius: 12px;
  overflow: hidden;
`;

export const ButtonItemMidia = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px;
`;

export const LabelButtonItemMidia = styled.Text`
  font-size: 18px;
  font-weight: 500;
`;
