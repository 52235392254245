import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import { useIsFocused } from "@react-navigation/native";
import * as ImagePicker from "expo-image-picker";
import useSWR, { useSWRConfig } from "swr";

import Midia from "../../models/midia.model";
import MidiaService from "@services/midia.service";

import BottomSheetSalvarMidia from "./bottom-sheet-salvar-midia";
import BottomSheetActionsMidia from "./bottom-sheet-actions-midia";

import FAB from "@components/fab";
import List from "@components/list";
import Screen from "@components/screen";
import ItemMidia from "@components/item-midia";
import HeaderBusca from "./header-busca";
import HeaderActions from "./header-actions";
import { BottomSheetRef } from "@components/bottom-sheet";

import MoreIconSvg from "@assets/vectors/list-item/more.svg";
import PlusIconSvg from "@assets/vectors/plus.svg";
import { useService } from "@hooks/use-service";
import { useMidiasQueue } from "@/utils/midias-queue.util";
import {
  gerarFiltroPeriodo,
  gerarFiltroTipo,
} from "@/utils/filtro-midias.util";

interface Props extends TabScreenProps<"Midias"> {}
type FetcherResponse = Record<"midias" | "pendentes" | "todas", Midia[]>;

function openBottomSheet(ref: React.RefObject<BottomSheetRef>) {
  setTimeout(() => {
    if (!ref.current?.isVisible()) ref.current?.open();
  }, 10);
}

function gerarParametros(routeParams: Props["route"]["params"]) {
  const params: any = {
    filtro: {
      $or: [
        { data_vencimento: { $exists: false } },
        {
          data_vencimento: {
            $gte: DateTime.local().startOf("second").toISO(),
          },
        },
      ],
    },
  };

  if (routeParams?.tipo) {
    params.filtro.tipo = gerarFiltroTipo(routeParams.tipo);
  }

  if (routeParams?.periodo) {
    params.filtro.data_criacao = gerarFiltroPeriodo(routeParams.periodo);
  }

  if (routeParams?.search) {
    params.search = routeParams?.search;
  }

  return params;
}

function Midias(props: Props) {
  const { navigation } = props;

  const routeParams = props.route.params;
  const bottomSheetActionsMidiaRef = useRef<BottomSheetRef>(null);
  const bottomSheetSalvarMidiaRef = useRef<BottomSheetRef>(null);

  const focused = useIsFocused();
  const midiaService = useService(MidiaService);
  const midiasPendentes = useMidiasQueue();
  const [midiaSelecionada, setMidiaSelecionada] = useState<Midia>();

  const [midiaUploading, setMidiaUploading] = useState<string>();
  const [uploadProgress, setUploadProgress] = useState(0);

  const fetcher = async () => {
    const params = gerarParametros(routeParams);
    const midias = (await midiaService.listar({ params })) || [];

    if (routeParams?.search) {
      return { midias: midias || [], pendentes: [], todas: midias };
    }

    const pendentes = (await midiasPendentes.list()) || [];

    return {
      midias: midias || [],
      pendentes: pendentes || [],
      todas: [...pendentes, ...midias],
    };
  };

  const { mutate } = useSWRConfig();
  const { data, isLoading, isValidating } = useSWR(
    ["/midias", routeParams.search || ""],
    fetcher
  );

  async function fazerUpload(midia: Midia) {
    try {
      setMidiaUploading(midia._id);
      setUploadProgress(0);

      type MutationResult = { midia: Midia; pendentes: Midia[] };

      async function mutation() {
        const result = await midiaService.upload(midia, setUploadProgress);
        return {
          midia: result,
          pendentes: await midiasPendentes.finish(),
        };
      }

      await mutate(["/midias", ""], mutation, {
        revalidate: false,
        populateCache(result: MutationResult, current: FetcherResponse) {
          const pendentes = result.pendentes || current?.pendentes || [];
          const midias = [...(current?.midias || []), result.midia];
          const todas = [...pendentes, ...midias];
          return { pendentes, midias, todas };
        },
      });
    } catch (error: any) {
      console.error("error upload", error);
      await midiasPendentes.remove(midia._id);
      await mutate(["/midias", ""]);
    } finally {
      setMidiaUploading(undefined);
      setUploadProgress(0);
    }
  }

  useEffect(() => {
    if (
      focused &&
      midiasPendentes.size() &&
      !midiasPendentes.current() &&
      !isValidating
    ) {
      fazerUpload(midiasPendentes.next());
    }
  }, [focused, data?.pendentes, isValidating]);

  async function listarMidias() {
    mutate(["/midias", ""]);
    setMidiaSelecionada(undefined);
  }

  async function criarMidias() {
    const status = await ImagePicker.getMediaLibraryPermissionsAsync();

    if (status?.granted) {
      return selecionarMidiasGaleria();
    }

    const permission = await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (permission.granted) {
      selecionarMidiasGaleria();
    } else if (permission.canAskAgain) {
      criarMidias();
    }
  }

  async function selecionarMidiasGaleria() {
    const result = await ImagePicker.launchImageLibraryAsync({
      allowsMultipleSelection: true,
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      base64: false,
      quality: 1,
    });

    if (!result.canceled) {
      navigation.navigate("CriarMidias", {
        assets: result.assets,
      });
    }
  }

  return (
    <Screen
      title="Repositório"
      style={{ paddingBottom: 0 }}
      contentContainerStyle={{ padding: 0 }}
      header={routeParams.search !== undefined && <HeaderBusca />}
      right={
        <HeaderActions
          onSearch={() => navigation.setParams({ search: "" })}
          onFilter={() =>
            navigation.navigate("BottomSheetFiltros", {
              tipo: routeParams.tipo,
              periodo: routeParams.periodo,
            })
          }
        />
      }
    >
      <List
        data={[...(data?.pendentes || []), ...(data?.midias || [])]}
        extraData={`${midiaUploading}:${uploadProgress}`}
        validating={isValidating}
        refreshing={isLoading}
        onRefresh={() => mutate(["/midias", ""])}
        emptyText="Nenhuma mídia encontrada"
        renderItem={({ item }) => (
          <Item
            item={item}
            progress={item._id === midiaUploading ? uploadProgress : 0}
            onAction={() => {
              setMidiaSelecionada(item);
              openBottomSheet(bottomSheetActionsMidiaRef);
            }}
          />
        )}
      />
      <FAB.List>
        <FAB.Item icon={PlusIconSvg} onPress={criarMidias} />
      </FAB.List>
      <BottomSheetActionsMidia
        ref={bottomSheetActionsMidiaRef}
        midia={midiaSelecionada}
        onEdit={() => openBottomSheet(bottomSheetSalvarMidiaRef)}
        onDelete={() =>
          navigation.navigate("ModalExcluirMidia", {
            midia: midiaSelecionada as Midia,
          })
        }
      />
      <BottomSheetSalvarMidia
        ref={bottomSheetSalvarMidiaRef}
        midia={midiaSelecionada}
        onSuccess={listarMidias}
      />
    </Screen>
  );
}

function Item(props: { item: Midia; progress: number; onAction: () => void }) {
  if (props.item.pendente) {
    return (
      <ItemMidia.Container>
        <ItemMidia.ImageProgress progress={props.progress} midia={props.item} />
        <ItemMidia.ContentProgress
          progress={props.progress}
          midia={props.item}
        />
        <ItemMidia.Action icon={MoreIconSvg} onPress={props.onAction} />
      </ItemMidia.Container>
    );
  }

  return (
    <ItemMidia.Container>
      <ItemMidia.Image midia={props.item} />
      <ItemMidia.Content midia={props.item} />
      <ItemMidia.Action icon={MoreIconSvg} onPress={props.onAction} />
    </ItemMidia.Container>
  );
}

export default Midias;
