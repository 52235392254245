import { AxiosInstance } from "axios";

import api from "../api";
import { AuthContextType } from "../contexts/auth.context";

export interface IService<T = any> {
  listar(search?: string | null): Promise<T[]>;
  criar(item: T): Promise<T>;
  buscar(id: string): Promise<T>;
  atualizar(item: T): Promise<T>;
  excluir(id: string): Promise<T>;
  setAuth(auth: AuthContextType): void;
}

export default abstract class Service<T extends Model> implements IService<T> {
  private token?: string;
  protected api: AxiosInstance;

  protected abstract Model: new (data: T) => T;
  protected abstract path: string;

  constructor(protected auth?: AuthContextType) {
    this.api = api;
    this.auth && this.setAuth(this.auth);
  }

  setAuth(auth: AuthContextType) {
    this.auth = auth;
    this.token = auth.token;
    this.api.defaults.headers["Authorization"] = `Bearer ${this.token}`;
  }

  async listar(options?: any) {
    if (!options) options = {};
    if (!options.params) options.params = {};

    if (typeof options.params?.filtro !== "string") {
      options.params.filtro = JSON.stringify(options?.params?.filtro);
    }

    const response = await this.api.get<T[]>(this.path, options);

    return response.data;
  }

  async criar(item: T) {
    const response = await this.api.post<T>(this.path, item);
    return new this.Model(response.data);
  }

  async buscar(id: string, options?: any) {
    const response = await this.api.get<T>(`${this.path}/${id}`, options);
    return new this.Model(response.data);
  }

  async atualizar(item: T) {
    const response = await this.api.put<T>(`${this.path}/${item._id}`, item);
    return new this.Model(response.data);
  }

  async excluir(id: string) {
    const response = await this.api.delete(`${this.path}/${id}`);
    return response.data;
  }

  async upsert(item: T) {
    if (item._id) {
      return await this.atualizar(item);
    } else {
      return await this.criar(item);
    }
  }
}
