import Svg from "@components/svg";
import { Container } from "./styles";

import DragIconSvg from "@assets/vectors/drag.svg";

interface Props {
  onDrag: () => void;
}

function DragButton(props: Props) {
  return (
    <Container onPressIn={props.onDrag}>
      <Svg data={DragIconSvg} width={24} height={24} />
    </Container>
  );
}

export default DragButton;
