import React from "react";
import { View } from "react-native";

function ProgressBar(props: { progress?: number }) {
  const progress = React.useMemo(() => {
    return Math.round((props.progress ?? 0) * 100);
  }, [props.progress]);

  return (
    <View
      style={{
        flex: 1,
        position: "absolute",
        backgroundColor: "#554af0aa",
        height: "100%",
        width: `${progress}%`,
        left: 0,
      }}
    />
  );
}

export default ProgressBar;
