import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform } from "react-native";

import Midia from "@models/midia.model";

class MidiasQueue {
  private midiasPendentes: Midia[] = [];
  private _current: Midia | null = null;
  static instance: MidiasQueue;

  private constructor() {
    this.list();
  }

  static getInstance() {
    if (!MidiasQueue.instance) MidiasQueue.instance = new MidiasQueue();
    return MidiasQueue.instance;
  }

  size() {
    return this.midiasPendentes.length;
  }

  get itens() {
    return this.midiasPendentes;
  }

  async addMany(midias: Midia[]) {
    this.midiasPendentes.push(...midias);

    if (Platform.OS !== "web") {
      await AsyncStorage.setItem(
        "midias-pendentes",
        JSON.stringify(this.midiasPendentes)
      );
    }

    return this.midiasPendentes;
  }

  async add(midia: Midia) {
    this.midiasPendentes.push(midia);

    if (Platform.OS !== "web") {
      await AsyncStorage.setItem(
        "midias-pendentes",
        JSON.stringify(this.midiasPendentes)
      );
    }
  }

  async remove(uploadId: string) {
    this.midiasPendentes = this.midiasPendentes.filter(
      (midia) => midia._id !== uploadId
    );

    if (Platform.OS !== "web") {
      await AsyncStorage.setItem(
        "midias-pendentes",
        JSON.stringify(this.midiasPendentes)
      );
    }

    return this.midiasPendentes;
  }

  async list() {
    if (Platform.OS === "web") return this.midiasPendentes;

    const midiasJson = await AsyncStorage.getItem("midias-pendentes");

    if (midiasJson) {
      this.midiasPendentes = JSON.parse(midiasJson) || [];
    }

    return this.midiasPendentes.map((midia: Midia) => new Midia(midia, true));
  }

  async shift() {
    const item = this.midiasPendentes.shift();

    if (Platform.OS !== "web") {
      await AsyncStorage.setItem(
        "midias-pendentes",
        JSON.stringify(this.midiasPendentes)
      );
    }

    return item;
  }

  finish() {
    const id = this._current?._id ?? "";
    this._current = null;

    return this.remove(id);
  }

  current() {
    return this._current;
  }

  next() {
    this._current = new Midia(this.midiasPendentes[0], true);
    return this._current;
  }
}

export function useMidiasQueue() {
  return MidiasQueue.getInstance();
}

export default MidiasQueue.getInstance();
