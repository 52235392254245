import { Platform } from "react-native";
import { DefaultTheme } from "styled-components/native";

const theme: DefaultTheme = {
  colors: {
    primary: "#554AF0",
    onPrimary: "#FFFFFF",
    secondary: "#F8F8FA",
    onSecondary: "#554AF0",
    background: "#f4f5f9",
    onBackground: "#000000",
    surface: "#FFFFFF",
    onSurface: "#000000",
    error: "#B00020",
    onError: "#FFFFFF",
    text: "#323232",
    disabled: "#F8F8FA",
    onDisabled: "#D2D2D7",
  },
  sizes: {
    topBar: 64,
    topBarContainer: 64,
    bottomBar: 60,
  },
};

if (Platform.OS === "web") {
  theme.sizes.topBarContainer = 150;
}

export default theme;
