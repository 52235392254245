import React, { useMemo, useState } from "react";
import { SvgProps } from "react-native-svg";
import {
  StyleProp,
  TextInput,
  TouchableOpacity,
  ViewStyle,
} from "react-native";

import DateTimeInput, { DateTimeInputRef } from "./date-time-input";
import { Container, Content, Label } from "./styles";

import Svg from "@components/svg";
import CloseIconSvg from "@assets/vectors/close.svg";

interface Props {
  label: string;
  style?: StyleProp<ViewStyle>;
  icon?: (props: IconProps) => React.FC<SvgProps>;
  right?: React.ReactNode;
  value: string;
  placeholder?: string;
  onChangeText: (value: string) => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  onTouchStart?: (e: any) => void;
}

interface IconProps {
  focused: boolean;
}

export type FieldDatetimeRef = TextInput;

function FieldDatetime(props: Props, ref: React.Ref<TextInput>) {
  const { icon, label, style, right, ...rest } = props;
  const inputRef = React.useRef<DateTimeInputRef>(ref as any);

  const [focused, setFocused] = useState(false);

  function handleFocus(e: any) {
    setFocused(true);
    props.onFocus?.(e);
  }

  function handleBlur(e: any) {
    setFocused(false);
    props.onBlur?.(e);
  }

  function handleClear() {
    props.onChangeText("");
    handleBlur(null);
  }

  function touchStart(e: any) {
    props.onTouchStart?.(e);
    handleFocus(e);
    inputRef.current?.focus();
  }

  return (
    <Container style={style} focused={focused}>
      <Content onTouchEnd={touchStart}>
        <Label>{label}</Label>
        {props.icon && (
          <Svg
            data={props.icon({ focused: focused })}
            style={{ marginLeft: 18 }}
            width={18}
            height={18}
          />
        )}
        <DateTimeInput
          ref={inputRef}
          {...rest}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {right}
      </Content>
      <TouchableOpacity style={{ padding: 6 }} onPress={handleClear}>
        <Svg data={CloseIconSvg} width={40} height={40} />
      </TouchableOpacity>
    </Container>
  );
}

export default React.forwardRef(FieldDatetime);
