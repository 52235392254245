import ItemMidiaAction from "./item-midia-action";
import ItemMidiaContainer from "./item-midia-container";
import ItemMidiaContent from "./item-midia-content";
import ItemMidiaContentProgress from "./item-midia-content-progress";
import ItemMidiaImage from "./item-midia-image";
import ItemMidiaImageProgress from "./item-midia-image-progress";

export default {
  Action: ItemMidiaAction,
  Container: ItemMidiaContainer,
  Content: ItemMidiaContent,
  ContentProgress: ItemMidiaContentProgress,
  Image: ItemMidiaImage,
  ImageProgress: ItemMidiaImageProgress,
};
