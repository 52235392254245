import React from "react";
import { SvgProps } from "react-native-svg";
import { useNavigation } from "@react-navigation/native";

import Svg from "@components/svg";
import { Container } from "./styles";

import BackIconSvg from "@assets/vectors/back.svg";

interface Props {
  icon?: React.FC<SvgProps>;
}

function BackButton(props: Props) {
  const navigation = useNavigation();

  return (
    <Container onPress={() => navigation.goBack()}>
      <Svg
        data={props.icon || BackIconSvg}
        stroke="#fff"
        width={28}
        height={28}
      />
    </Container>
  );
}

export default BackButton;
