import React, { useRef } from "react";

import Button from "@components/button";
import Modal, { ModalRef } from "@components/modal";
import PlaylistService from "@services/playlist.service";
import { useService } from "@hooks/use-service";
import { Row, Text } from "@/global-styles";

interface Props extends StackScreenProps<"ModalExcluirPlaylist"> {}

function ModalExcluirPlaylist(props: Props) {
  const { navigation } = props;

  const playlist = props.route.params?.playlist;
  const modalRef = useRef<ModalRef>(null);
  const playlistService = useService(PlaylistService);

  const [excluindo, setExcluindo] = React.useState(false);

  React.useLayoutEffect(() => {
    modalRef.current?.open();
  }, []);

  function closeModal() {
    navigation.goBack();
  }

  async function excluirPlaylist() {
    if (!playlist) return;

    try {
      setExcluindo(true);
      await playlistService.excluir(playlist._id);
      modalRef.current?.close();
    } catch (error: any) {
      if (error.isAxiosError) {
        console.log("axiosError", error.response?.data);
      } else {
        console.log("error", error);
      }
    } finally {
      setExcluindo(false);
    }
  }

  return (
    <Modal ref={modalRef} title="Tem certeza disso?" onClose={closeModal}>
      <Row>
        <Text>
          Você está prestes a remover a playlist{" "}
          <Text style={{ color: "#554AF0" }}>{playlist?.nome}</Text>. Clique em
          confirmar para remover a playlist da playlist.
        </Text>
      </Row>
      <Row>
        <Button
          color="secondary"
          text="Cancelar"
          onPress={() => modalRef.current?.close()}
          disabled={excluindo}
        />
      </Row>
      <Button
        color="primary"
        text="Confirmar"
        onPress={excluirPlaylist}
        loading={excluindo}
      />
    </Modal>
  );
}

export default ModalExcluirPlaylist;
