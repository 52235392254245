import { Container, Label } from "./styles";

interface Props {
  checked: boolean;
  children: string;
  onPress: () => void;
}

function FiltroItemCheckbox(props: Props) {
  const { checked, onPress } = props;

  return (
    <Container checked={checked} onPress={onPress}>
      <Label checked={checked}>{props.children}</Label>
    </Container>
  );
}

export default FiltroItemCheckbox;
