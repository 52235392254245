import { Video as ExpoVideo } from "expo-av";
import styled from "styled-components/native";

export const Container = styled.View`
  flex: 1;
`;

export const Video = styled(ExpoVideo)`
  flex: 1;
`;
