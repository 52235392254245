import { Camera } from "expo-camera";
import {
  Container,
  RequestPermissionsButton,
  RequestPermissionsButtonText,
  TextInfo,
} from "./styles";

interface Props {}

function RequestPermissions(props: Props) {
  const [permission, requestPermission] = Camera.useCameraPermissions();

  if (!permission) {
    return (
      <Container>
        <TextInfo>Solicitando permissão de acesso a câmera</TextInfo>
      </Container>
    );
  }

  return (
    <Container>
      <TextInfo>
        Para continuar precisamos da permissão de acesso a câmera
      </TextInfo>
      <RequestPermissionsButton onPress={requestPermission}>
        <RequestPermissionsButtonText>
          Conceder permissões
        </RequestPermissionsButtonText>
      </RequestPermissionsButton>
    </Container>
  );
}

export default RequestPermissions;
