import React from "react";
import { DateTime } from "luxon";

import Tela from "@models/tela.model";
import Svg from "@components/svg";
import ItemMidia from "@components/item-midia";
import { filtrarMidiasVencidas } from "@/utils/filtro-midias.util";
import {
  Container,
  Content,
  Description,
  ImageContainer,
  MidiaCount,
  StatusDot,
  Title,
} from "./styles";

import TelaImageSvg from "@assets/vectors/list-item/item-tela-image.svg";

interface Props {
  item: Tela;
}

function ItemTela(props: Props) {
  const tela = props.item;
  const { nome, descricao, data_conexao, ativa } = props.item;

  const date = DateTime.fromISO(data_conexao).toLocaleString(
    DateTime.DATE_FULL
  );
  const time = DateTime.fromISO(data_conexao).toLocaleString(
    DateTime.TIME_SIMPLE
  );

  const quantidade = React.useMemo(() => {
    return filtrarMidiasVencidas(tela.playlist?.itens || []).length;
  }, [tela.playlist?.itens]);

  return (
    <Container>
      <ImageContainer>
        {tela.ativa && tela.midia_atual ? (
          <ItemMidia.Image midia={tela.midia_atual} />
        ) : (
          <Svg data={TelaImageSvg} width={60} height={60} />
        )}
        <StatusDot active={ativa as boolean} />
      </ImageContainer>
      <Content>
        <Title>{nome}</Title>
        <Description>{descricao}</Description>
        {tela.ativa ? (
          !!tela.status ? (
            <Description>{tela.status}</Description>
          ) : (
            <Description>Reproduzindo: {tela.midia_atual?.nome}</Description>
          )
        ) : (
          <Description>
            Última conexão em {date} às {time}
          </Description>
        )}
        <MidiaCount>{quantidade} Mídias</MidiaCount>
      </Content>
    </Container>
  );
}

export default ItemTela;
