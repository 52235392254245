import styled from "styled-components";

export const Container = styled.div`
  width: 80px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 768px) and (orientation: landscape) {
    height: 80px;
  }
`;

interface LabelProps {
  focused: boolean;
}

export const Label = styled.span<LabelProps>`
  font-size: 12px;
  margin-top: 4px;
  color: ${(props) => (props.focused ? props.theme.colors.primary : "#686777")};

  @media (min-width: 768px) and (orientation: landscape) {
    margin-top: 8px;
  }
`;
