import { StatusBar } from "expo-status-bar";

import VisualizarMidiaHeader from "./visualizar-midia-header";
import VisualizarMidiaImage from "./visualizar-midia-image";
import VisualizarMidiaVideo from "./visualizar-midia-video";

import { Container, Content } from "./styles";
import { useSafeAreaInsets } from "react-native-safe-area-context";

interface Props extends StackScreenProps<"VisualizarMidia"> {}

function VisualizarMidia(props: Props) {
  const insets = useSafeAreaInsets();
  const routeParams = props.route.params;

  return (
    <Container insets={insets}>
      <StatusBar style="light" backgroundColor="#121212" />
      <VisualizarMidiaHeader titulo={routeParams.nome} />
      <Content>
        {routeParams.tipo === "image" && (
          <VisualizarMidiaImage uri={routeParams.uri} />
        )}
        {routeParams.tipo === "video" && (
          <VisualizarMidiaVideo uri={routeParams.uri} />
        )}
      </Content>
    </Container>
  );
}

export default VisualizarMidia;
