import { useLayoutEffect, useState } from "react";
import { Platform } from "react-native";

export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(false);

  if (Platform.OS === "web") {
    useLayoutEffect(() => {
      const mediaQueryList = window.matchMedia(query);
      setMatches(mediaQueryList.matches);
      const listener = () => setMatches(mediaQueryList.matches);
      mediaQueryList.addListener(listener);
      return () => mediaQueryList.removeListener(listener);
    }, [query]);
  }

  return matches;
}

export function useIsMobile() {
  return useMediaQuery("(max-width: 768px)");
}

export function useIsTablet() {
  return useMediaQuery("(min-width: 768px) and (max-width: 1024px)");
}

export function useIsDesktop() {
  return useMediaQuery("(min-width: 768px) and (orientation: landscape)");
}
