import { Fragment } from "react";
import styled from "styled-components/native";

export const Container = styled.View`
  flex-direction: row;
  align-items: center;
  margin-right: -8px;
`;

export const ActionButton = styled.TouchableOpacity`
  padding: 12px;
  margin-left: 4px;
`;
