import ItemMidia from "@components/item-midia";
import Midia from "@models/midia.model";
import { CheckBox } from "./styles";

interface Props {
  item: Midia;
  selecionado?: boolean;
  onSelecionar: (selecionado: boolean) => void;
}

function ItemSelecionarMidias(props: Props) {
  const { item, selecionado } = props;

  function handleSelecionar() {
    props.onSelecionar(!selecionado);
  }

  return (
    <ItemMidia.Container onPress={handleSelecionar}>
      <CheckBox
        style={{ marginRight: 14 }}
        onValueChange={props.onSelecionar}
        value={selecionado}
      />
      <ItemMidia.Image midia={item} />
      <ItemMidia.Content midia={item} />
    </ItemMidia.Container>
  );
}

export default ItemSelecionarMidias;
