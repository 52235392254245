import React from "react";
import { useIsFocused } from "@react-navigation/native";
import { Alert } from "react-native";
import { Camera } from "expo-camera";
import useSWR from "swr";

import ItemTela from "./item-tela";
import FAB from "@components/fab";
import List from "@components/list";
import Screen from "@components/screen";
import TelaService from "@services/tela.service";
import BottomSheetCriarTela from "./bottom-sheet-criar-tela";
import { BottomSheetRef } from "@components/bottom-sheet";
import { useService } from "@hooks/use-service";
import { useIsDesktop } from "@hooks/use-media-query";

import PlusIconSvg from "@assets/vectors/plus.svg";

interface Props extends TabScreenProps<"Telas"> {}

function Telas(props: Props) {
  const { navigation, route } = props;
  const bottomSheetRef = React.useRef<BottomSheetRef>(null);

  const focused = useIsFocused();
  const isDesktop = useIsDesktop();
  const telaService = useService(TelaService);
  const fetcher = () => {
    return telaService.listar({
      params: { populate: ["playlist", "midia_atual"] },
    });
  };

  const { data, isLoading, isValidating, mutate } = useSWR("/telas", fetcher, {
    refreshInterval: 2000,
    isVisible: () => focused,
  });

  const telas = data || [];

  React.useLayoutEffect(() => {
    if (focused && route.params?.scanned) {
      bottomSheetRef.current?.open();
    }
  }, [focused, route.params]);

  async function onAdicionarTela() {
    const status = await Camera.getCameraPermissionsAsync();

    if (status?.granted) {
      navigation.navigate("TelaQrCode");
    }

    const permission = await Camera.requestCameraPermissionsAsync();

    if (permission.granted) {
      navigation.navigate("TelaQrCode");
    } else if (permission.canAskAgain) {
      onAdicionarTela();
    } else {
      Alert.alert(
        "Permissão de câmera negada",
        "Você precisa permitir o acesso à câmera para adicionar uma tela."
      );
    }
  }

  return (
    <Screen
      title="Telas"
      style={{ paddingBottom: 0 }}
      contentContainerStyle={{ padding: 0 }}
    >
      <List
        data={telas}
        keyExtractor={(item) => item.mac}
        refreshing={isLoading}
        validating={isValidating}
        renderItem={({ item }) => <ItemTela item={item} />}
        emptyText="Nenhuma tela encontrada"
      />
      <BottomSheetCriarTela
        ref={bottomSheetRef}
        onSuccess={(tela) => mutate([...telas, tela])}
        onClose={() => navigation.setParams({ scanned: false })}
      />
      {!isDesktop && (
        <FAB.List>
          <FAB.Item icon={PlusIconSvg} onPress={onAdicionarTela} />
        </FAB.List>
      )}
    </Screen>
  );
}

export default Telas;
