import React from "react";

import BottomSheet, { BottomSheetRef } from "@components/bottom-sheet";
import Button from "@components/button";
import Divider from "@components/divider";
import FieldText from "@components/field-text";
import FieldDatetime from "@components/field-datetime";
import Midia from "../../../models/midia.model";

import { Container, Form } from "./styles";
import { Row, Text } from "@/global-styles";

import EditIconSvg from "@assets/vectors/edit.svg";
import EditIconActiveSvg from "@assets/vectors/edit-active.svg";
import ItemMidia from "@components/item-midia";

interface Props {
  midia?: Midia;
  onSave: (dados: {
    nome: string;
    data_inicio: string;
    data_vencimento: string;
  }) => void;
}

function BottomSheetRenameMidia(
  props: Props,
  ref: React.ForwardedRef<BottomSheetRef>
) {
  const sheetRef = ref as React.MutableRefObject<BottomSheetRef>;
  const [nome, setNome] = React.useState(props.midia?.nome || "");
  const [inicio, setInicio] = React.useState(props.midia?.data_inicio || "");
  const [vencimento, setVencimento] = React.useState(
    props.midia?.data_vencimento || ""
  );

  function handleSave() {
    props.onSave({
      nome: nome.trim(),
      data_vencimento: vencimento,
      data_inicio: inicio,
    });

    sheetRef.current?.close();
  }

  return (
    <BottomSheet
      ref={ref}
      hasHeader={false}
      contentContainerStyle={{ padding: 12 }}
      onOpen={() => {
        setNome(props.midia?.nome || "");
      }}
    >
      <Container>
        {props.midia && (
          <ItemMidia.Container>
            <ItemMidia.Image midia={props.midia} />
            <ItemMidia.Content midia={props.midia} />
          </ItemMidia.Container>
        )}
        <Divider />
        <Form>
          <Text size={16} style={{ marginBottom: 24 }}>
            Adicione um nome à mídia selecionada.
          </Text>
          <Row>
            <FieldText
              label="Inserir Nome"
              icon={({ focused }) =>
                focused ? EditIconActiveSvg : EditIconSvg
              }
              placeholder="Nome da mídia"
              onChangeText={setNome}
              value={nome}
            />
          </Row>
          <Row>
            <FieldDatetime
              label="Data de início"
              placeholder="Data de início"
              onChangeText={setInicio}
              value={inicio}
            />
          </Row>
          <Row>
            <FieldDatetime
              label="Data de vencimento"
              placeholder="Data de vencimento"
              onChangeText={setVencimento}
              value={vencimento}
            />
          </Row>
          <Button
            color="primary"
            text="Confirmar"
            onPress={handleSave}
            disabled={!nome}
          />
        </Form>
      </Container>
    </BottomSheet>
  );
}

export default React.forwardRef(BottomSheetRenameMidia);
