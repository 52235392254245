import React, { useMemo, useState } from "react";
import { StyleProp, TextInput, TextInputProps, ViewStyle } from "react-native";
import { SvgProps } from "react-native-svg";

import Svg from "@components/svg";
import { Container, Input, Label } from "./styles";

interface Props extends TextInputProps {
  label: string;
  style?: StyleProp<ViewStyle>;
  icon?: (props: IconProps) => React.FC<SvgProps>;
  right?: React.ReactNode;
}

interface IconProps {
  focused: boolean;
}

export type FieldTextRef = TextInput;

function FieldText(props: Props, ref: React.Ref<TextInput>) {
  const { icon, label, style, right, ...rest } = props;
  const inputRef = React.useRef<TextInput>(ref as any);

  const [focused, setFocused] = useState(false);

  const Icon = useMemo(() => {
    if (props.icon) {
      return props.icon?.({ focused: focused });
    }
  }, [focused]);

  function handleFocus(e: any) {
    setFocused(true);
    props.onFocus?.(e);
  }

  function handleBlur(e: any) {
    setFocused(false);
    props.onBlur?.(e);
  }

  function touchStart(e: any) {
    props.onTouchStart?.(e);
    handleFocus(e);
    inputRef.current?.focus();
  }

  return (
    <Container style={style} focused={focused} onTouchStart={touchStart}>
      <Label>{label}</Label>
      {Icon && (
        <Svg data={Icon} style={{ marginLeft: 18 }} width={18} height={18} />
      )}
      <Input
        ref={inputRef}
        {...rest}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {right}
    </Container>
  );
}

export default React.forwardRef(FieldText);
