import styled from "styled-components/native";

export const Container = styled.View`
  flex: 1;
  padding: 20px;
  flex-direction: row;
  max-height: 60px;
  align-items: center;
  background-color: #121212;
`;

export const Title = styled.Text`
  position: absolute;
  text-align: center;
  font-size: 20px;
  z-index: -1;
  left: 0;
  right: 0;
  color: #fff;
`;
