import {
  DragEndParams,
  ShadowDecorator,
} from "react-native-draggable-flatlist";

import DragButton from "../drag-button";
import Midia from "@models/midia.model";

import ItemMidia from "@components/item-midia";
import { DraggableList } from "@components/list";

import TrashIconSvg from "@assets/vectors/list-item/trash.svg";

interface Props {
  itens: Midia[];
  refreshing?: boolean;
  validating?: boolean;
  onDragEnd: (data: DragEndParams<Midia>) => void;
  onDelete: (midia: Midia) => void;
}

function List(props: Props) {
  return (
    <DraggableList
      data={props.itens}
      onDragEnd={props.onDragEnd}
      keyExtractor={(item) => item._id}
      refreshing={props.refreshing}
      validating={props.validating}
      emptyText="Essa playlist ainda não possui nenuma mídia"
      renderItem={({ item, drag }) => (
        <ShadowDecorator>
          <ItemMidia.Container>
            <DragButton onDrag={drag} />
            <ItemMidia.Image midia={item} />
            <ItemMidia.Content midia={item} />
            <ItemMidia.Action
              icon={TrashIconSvg}
              onPress={() => props.onDelete(item)}
              width={24}
              height={24}
            />
          </ItemMidia.Container>
        </ShadowDecorator>
      )}
    />
  );
}

export default List;
