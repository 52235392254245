import React from "react";
import { TouchableOpacity } from "react-native";

import Button from "@components/button";
import Divider from "@components/divider";
import BottomSheet, { BottomSheetRef } from "@components/bottom-sheet";
import ModalCloseButton from "@components/modal/modal-close-button";
import { Row, Text } from "@/global-styles";

import FiltroItemCheckbox from "./filtro-item-checkbox";
import { Container, FiltroItemCheckboxContainer } from "./styles";

const tipos_midia = [
  { id: "all", nome: "Todos" },
  { id: "image", nome: "Imagem" },
  { id: "video", nome: "Vídeo" },
];

const periodo = [
  { id: "today", nome: "Hoje" },
  { id: "yesterday", nome: "Ontem" },
  { id: "past_month", nome: "Mês passado" },
  { id: "current_month", nome: "Mês atual" },
  { id: "select", nome: "Selecionar período" },
];

interface Props extends StackScreenProps<"BottomSheetFiltros"> {}

type FiltroAction =
  | { type: "clear" }
  | {
      type: "data" | "tipo";
      payload: string;
    };

type FiltroState = {
  tipo?: string;
  periodo?: string;
};

function filtroReducer(state: FiltroState, action: FiltroAction) {
  switch (action.type) {
    case "clear":
      return { tipo: undefined, periodo: undefined };
    case "data":
      return { ...state, periodo: action.payload };
    case "tipo":
      return { ...state, tipo: action.payload };
    default:
      return state;
  }
}

function BottomSheetFiltros(props: Props) {
  const { navigation } = props;
  const routeParams = props.route.params;
  const sheetRef = React.useRef<BottomSheetRef>(null);
  const [state, dispatch] = React.useReducer(filtroReducer, routeParams);

  React.useEffect(() => {
    sheetRef.current?.open();
  }, []);

  const left = React.useMemo(
    () => <ModalCloseButton onPress={() => sheetRef.current?.close()} />,
    []
  );

  const right = React.useMemo(
    () => (
      <TouchableOpacity
        hitSlop={{ bottom: 32, top: 32 }}
        onPress={() => dispatch({ type: "clear" })}
      >
        <Text size={16} color="primary">
          Limpar
        </Text>
      </TouchableOpacity>
    ),
    []
  );

  const onClose = React.useCallback(() => {
    navigation.goBack();
    navigation.reset({
      routes: [
        {
          name: "Midias",
          params: { tipo: state.tipo, periodo: state.periodo },
        },
      ],
    });
  }, [state]);

  return (
    <BottomSheet
      ref={sheetRef}
      contentContainerStyle={{ padding: 24 }}
      onClose={onClose}
      header={{ title: "Filtros", titleAlign: "center", left, right }}
    >
      <Divider />
      <Container>
        <Text size={16} style={{ fontWeight: "500" }} color="text">
          Tipo de mídia
        </Text>
        <FiltroItemCheckboxContainer>
          {tipos_midia.map((tipo) => (
            <FiltroItemCheckbox
              key={tipo.id}
              checked={state.tipo === tipo.id}
              onPress={() => dispatch({ type: "tipo", payload: tipo.id })}
            >
              {tipo.nome}
            </FiltroItemCheckbox>
          ))}
        </FiltroItemCheckboxContainer>
        <Text size={16} style={{ fontWeight: "500" }} color="text">
          Data de upload
        </Text>
        <FiltroItemCheckboxContainer>
          {periodo.map((data) => (
            <FiltroItemCheckbox
              key={data.id}
              checked={state.periodo === data.id}
              onPress={() => dispatch({ type: "data", payload: data.id })}
            >
              {data.nome}
            </FiltroItemCheckbox>
          ))}
        </FiltroItemCheckboxContainer>
        <Row>
          <Divider />
        </Row>
        <Button
          color="primary"
          text="Filtrar resultados"
          onPress={() => sheetRef.current?.close()}
        />
      </Container>
    </BottomSheet>
  );
}

export default BottomSheetFiltros;
