import * as ImagePicker from "expo-image-picker";
import React from "react";
import { useSWRConfig } from "swr";

import { obterMidiasPelosAssets } from "@/utils/blob.util";
import { useMidiasQueue } from "@/utils/midias-queue.util";
import { BottomSheetRef } from "@components/bottom-sheet";
import Button from "@components/button";
import List from "@components/list";
import Screen from "@components/screen";
import BackButton from "@components/screen/back-button";
import { useIsDesktop } from "@hooks/use-media-query";
import Midia from "@models/midia.model";
import BottomSheetRenameMidia from "./bottom-sheet-rename-midia";

import { SaveButtonContainer } from "./styles";

import CloseIconSvg from "@assets/vectors/close-button.svg";
import EditIconSvg from "@assets/vectors/edit.svg";
import ItemMidia from "@components/item-midia";

interface Props extends StackScreenProps<"CriarMidias"> {}
type FetcherResponse = Record<"midias" | "pendentes", Midia[]>;

function CriarMidias(props: Props) {
  const { navigation } = props;
  const routeParams = props.route.params;
  const bottomSheetRenameMidiaRef = React.useRef<BottomSheetRef>(null);

  const { mutate } = useSWRConfig();
  const isDesktop = useIsDesktop();
  const midiasQueue = useMidiasQueue();

  const [midias, setMidias] = React.useState<Midia[]>();
  const [totalMidias, setTotalMidias] = React.useState(0);
  const [midiaSelecionada, setMidiaSelecionada] = React.useState<Midia>();
  const [loading, setLoading] = React.useState(false);

  function handleRenomeMidia(midia: Midia) {
    setMidiaSelecionada(midia);
    bottomSheetRenameMidiaRef.current?.open();
  }

  async function handleSalvarMidias() {
    const result = await midiasQueue.addMany(midias as unknown as Midia[]);

    mutate(["/midias", ""], result, {
      revalidate: false,
      populateCache(result, current: FetcherResponse) {
        return {
          pendentes: result,
          midias: current?.midias || [],
        };
      },
    });

    navigation.goBack();
  }

  function handleEditarMidia(dados: { nome: string; data_vencimento: string }) {
    setMidias((midias) => {
      return midias?.map((midia) => {
        if (midia._id === midiaSelecionada?._id) {
          midia.nome = dados.nome;
          midia.data_vencimento = dados.data_vencimento;
        }

        return midia;
      });
    });
  }

  async function adicionarAssets(assets: ImagePicker.ImagePickerAsset[]) {
    setLoading(true);
    setTotalMidias(assets.length);

    try {
      const itens = await obterMidiasPelosAssets(assets);
      setMidias(itens);
    } catch (error) {
      console.log("Erro ao adicionar os assets", error);
    } finally {
      setLoading(false);
    }
  }

  React.useLayoutEffect(() => {
    if (routeParams?.assets) {
      adicionarAssets(routeParams.assets);
    }
  }, [routeParams?.assets]);

  return (
    <Screen
      title="Editar mídias"
      contentContainerStyle={{ padding: 0 }}
      left={<BackButton icon={CloseIconSvg} />}
      right={
        isDesktop && (
          <Button
            small
            text="Salvar mídias"
            onPress={handleSalvarMidias}
            color="onPrimary"
          />
        )
      }
    >
      <List
        data={midias}
        refreshing={loading || totalMidias !== midias?.length}
        renderItem={({ item }) => (
          <ItemMidia.Container onPress={() => handleRenomeMidia(item)}>
            <ItemMidia.Image midia={item} />
            <ItemMidia.Content midia={item} />
            <ItemMidia.Action
              icon={EditIconSvg}
              width={24}
              height={24}
              onPress={() => handleRenomeMidia(item)}
            />
          </ItemMidia.Container>
        )}
      />
      {isDesktop || (
        <SaveButtonContainer>
          <Button
            text="Salvar mídias"
            onPress={handleSalvarMidias}
            loading={!midias || totalMidias !== midias.length}
          />
        </SaveButtonContainer>
      )}
      <BottomSheetRenameMidia
        ref={bottomSheetRenameMidiaRef}
        midia={midiaSelecionada}
        onSave={handleEditarMidia}
      />
    </Screen>
  );
}

export default CriarMidias;
