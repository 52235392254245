import { Camera } from "expo-camera";
import { Dimensions } from "react-native";
import { EdgeInsets } from "react-native-safe-area-context";
import styled from "styled-components/native";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;
const cameraWidth = Dimensions.get("window").height * (9 / 16);
const cameraPadding = (cameraWidth - screenWidth) / 2;

export const Container = styled.View`
  flex: 1;
`;

export const ScannerCamera = styled(Camera)<{ insets: EdgeInsets }>`
  width: ${cameraWidth}px;
  height: ${(props) =>
    screenHeight + props.insets.bottom - props.theme.sizes.topBar}px;
  padding: 0 ${cameraPadding}px;
  position: absolute;
  left: ${cameraPadding * -1}px;
  right: ${cameraPadding * -1}px;
`;

export const InfoText = styled.Text`
  font-size: 18px;
  text-align: center;
  color: white;
  position: absolute;
  padding: 36px;
  bottom: 36px;
  left: 0;
  right: 0;
  text-shadow: 0 0 2px #000;
`;
