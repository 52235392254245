import { Platform } from "react-native";
import { v4 as uuidv4 } from "uuid";
import * as Crypto from "expo-crypto";
import * as ImagePicker from "expo-image-picker";

import Midia from "@models/midia.model";

export function obterRandomId() {
  return Platform.OS === "web" ? uuidv4() : Crypto.randomUUID();
}

export function obterExtensaoPeloNome(filename: string) {
  return filename.split(".").pop() || "";
}

export function obterMimeType(tipo: "image" | "video", extensao: string) {
  return `${tipo}/${extensao}`;
}

export async function obterBlobPelaURI(
  tipo: "image" | "video",
  uri?: string
): Promise<Blob> {
  if (!uri) throw new Error("URI da mídia não informada");

  if (Platform.OS === "web") return obterBlobPelaUriWeb(uri);
  return obterBlobPelaUriNative(tipo, uri);
}

export async function obterBlobPelaUriWeb(uri: string) {
  const response = await fetch(uri || "");
  return await response.blob();
}

export async function obterBlobPelaUriNative(
  tipo: "image" | "video",
  uri: string
) {
  const filename = uri?.split("/").pop() || "";
  const extensao = obterExtensaoPeloNome(filename);

  return {
    uri,
    type: obterMimeType(tipo, extensao),
    name: filename,
  } as unknown as Blob;
}

async function obterDurationPeloAssetWeb(asset: ImagePicker.ImagePickerAsset) {
  const video = document.createElement("video");

  return new Promise<number>((resolve) => {
    video.src = asset.uri;
    video.addEventListener("loadedmetadata", (data) => {
      resolve(video.duration * 1000);
      video.remove();
    });
  }).finally(() => {
    video.remove();
  });
}

export async function obterDurationPeloAsset(
  asset: ImagePicker.ImagePickerAsset
) {
  if (asset.type !== "video") return 15000;

  if (Platform.OS === "web") {
    return obterDurationPeloAssetWeb(asset);
  }

  return asset.duration as number;
}

export async function polyfillAsset(asset: ImagePicker.ImagePickerAsset) {
  if (Platform.OS === "web") {
    const blob = await obterBlobPelaUriWeb(asset.uri);

    asset.uri = URL.createObjectURL(blob);
    asset.type = blob.type.split("/")[0] as "image" | "video";
  }

  asset.duration = await obterDurationPeloAsset(asset);

  return asset;
}

export async function obterMidiaPeloAsset(
  asset: ImagePicker.ImagePickerAsset,
  index: number
): Promise<Midia> {
  const assetPolyfilled = await polyfillAsset(asset);

  return {
    _id: obterRandomId(),
    nome: "Midia " + (index + 1),
    link_midia: assetPolyfilled.uri,
    tipo: assetPolyfilled.type as "image" | "video",
    tempo: assetPolyfilled.duration as number,
    data_criacao: new Date().toISOString(),
    pendente: true,
  };
}

export async function obterMidiasPelosAssets(
  assets: ImagePicker.ImagePickerAsset[]
) {
  const midias = [];

  for (let i = 0; i < assets.length; i++) {
    const asset = assets[i];
    const midia = await obterMidiaPeloAsset(asset, i);

    midias.push(midia);
  }

  return midias;
}
