import { Container, ContainerProps, Loading, Text, Touchable } from "./styles";

interface Props {
  onPress?: () => void;
  text: string;
  color?: ContainerProps["color"];
  disabled?: boolean;
  loading?: boolean;
  small?: boolean;
}

function Button(props: Props) {
  const { disabled, loading, small, color = "primary" } = props;
  const isDisabled = disabled || loading;

  return (
    <Container color={color} disabled={isDisabled}>
      <Touchable onPress={props.onPress} disabled={isDisabled}>
        {loading ? (
          <Loading small={small} />
        ) : (
          <Text color={color} disabled={isDisabled} small={small}>
            {props.text}
          </Text>
        )}
      </Touchable>
    </Container>
  );
}

export default Button;
