// import styled from "styled-components/native";
import styled from "styled-components";
import { EdgeInsets } from "react-native-safe-area-context";
import { ViewProps } from "react-native";

export interface ContainerProps {
  insets: EdgeInsets;
  style?: {
    paddingBottom?: number;
    paddingTop?: number;
    backgroundColor?: string;
  };
}

export const Container = styled.div.attrs((props: ContainerProps) => ({
  style: props.style,
}))<ContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f4f5f9;
  padding-top: ${(props) => props.style?.paddingTop || props.insets.top}px;
  padding-bottom: ${(props) =>
    props.style?.paddingBottom || props.insets.bottom}px;
  padding-left: ${(props) => props.insets.left}px;
  padding-right: ${(props) => props.insets.right}px;
`;

export interface ContentProps extends ViewProps {
  style?: {
    padding?: number;
  };
}

export const Content = styled.div<ContentProps>`
  flex: 1;
  padding: ${(props) => props.style?.padding ?? 24}px;
`;

export const TopBarContainer = styled.div`
  height: ${(props) => props.theme.sizes.topBar}px;
  background-color: ${(props) => props.theme.colors.primary};

  @media (min-width: 768px) and (orientation: landscape) {
    height: ${(props) => props.theme.sizes.topBarContainer}px;
  }
`;

export const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 24px;
  align-items: center;
  height: ${(props) => props.theme.sizes.topBar}px;
  background-color: ${(props) => props.theme.colors.primary};

  @media (min-width: 768px) and (orientation: landscape) {
    height: 90px;
    padding-left: 10vw;
    padding-right: 10vw;
    margin: auto;
  }

  @media (min-width: 1280px) and (orientation: landscape) {
    padding-left: 0;
    padding-right: 0;
    max-width: 1024px;
  }
`;

export const TopBarTitle = styled.span`
  flex: 1;
  font-size: 20px;
  font-weight: bold;
  font-family: "Roboto";
  color: ${(props) => props.theme.colors.onPrimary};
`;

export const TopBarActionsLeft = styled.div`
  display: flex;
  flex-shrink: 1;
  flex-direction: row;
  margin-right: 10px;
`;

export const TopBarActionsRight = styled.div`
  flex-shrink: 1;
  flex-direction: row;
`;
