import React from "react";
import { TouchableOpacity } from "react-native";
import { Controller, UseFormReturn, useForm } from "react-hook-form";

import Midia from "@models/midia.model";
import Svg from "@components/svg";
import Button from "@components/button";
import Divider from "@components/divider";
import ItemMidia from "@components/item-midia";
import FieldText from "@components/field-text";
import MidiaService from "@services/midia.service";
import FieldDatetime from "@components/field-datetime";
import BottomSheet, { BottomSheetRef } from "@components/bottom-sheet";
import { useService } from "@hooks/use-service";

import { Container, Form } from "./styles";
import { Row, Text } from "@/global-styles";

import EditIconSvg from "@assets/vectors/edit.svg";
import EditIconActiveSvg from "@assets/vectors/edit-active.svg";

interface Props {
  midia?: Midia;
  onSuccess: () => void;
}

function selectValues(midia?: Midia) {
  return {
    _id: midia?._id || "",
    nome: midia?.nome || "",
    data_inicio: midia?.data_inicio || "",
    data_vencimento: midia?.data_vencimento || "",
  };
}

function BottomSheetSalvarMidia(
  props: Props,
  ref: React.ForwardedRef<BottomSheetRef>
) {
  const sheetRef = ref as React.MutableRefObject<BottomSheetRef>;
  const midiaService = useService(MidiaService);

  const form = useForm<Midia>({
    resetOptions: { keepValues: false },
    defaultValues: selectValues(props.midia),
  });

  async function salvarMidia(data: Midia) {
    try {
      const midia = form.getValues();
      await midiaService.atualizar(midia);

      props.onSuccess();
      sheetRef.current?.close();
    } catch (error: any) {
      if (error.isAxiosError) {
        console.log("axiosError", error.response?.data);
      } else {
        console.log("error", error);
      }
    }
  }

  return (
    <BottomSheet
      ref={ref}
      hasHeader={false}
      contentContainerStyle={{ padding: 12 }}
      onOpen={() => form.reset(selectValues(props.midia))}
      onClose={() => form.reset()}
    >
      <Container>
        {props.midia && <Header midia={props.midia} form={form} />}
        <Divider />
        <Form>
          <Text size={16} style={{ marginBottom: 24 }}>
            Adicione um nome à mídia selecionada.
          </Text>
          <Row>
            <Controller
              name="nome"
              control={form.control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <FieldText
                  label="Inserir Nome"
                  icon={({ focused }) =>
                    focused ? EditIconActiveSvg : EditIconSvg
                  }
                  placeholder="Nome da mídia"
                  onChangeText={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              )}
            />
          </Row>
          <Row>
            <Controller
              name="data_inicio"
              control={form.control}
              render={({ field: { onChange, onBlur, value } }) => (
                <FieldDatetime
                  label="Data de início"
                  placeholder="Data de início"
                  onChangeText={onChange}
                  value={value || ""}
                  onBlur={onBlur}
                />
              )}
            />
          </Row>
          <Row>
            <Controller
              name="data_vencimento"
              control={form.control}
              render={({ field: { onChange, onBlur, value } }) => (
                <FieldDatetime
                  label="Data de vencimento"
                  placeholder="Data de vencimento"
                  onChangeText={onChange}
                  value={value || ""}
                  onBlur={onBlur}
                />
              )}
            />
          </Row>
          <Button
            color="primary"
            text="Confirmar"
            onPress={form.handleSubmit(salvarMidia)}
            disabled={!form.formState.isDirty}
            loading={form.formState.isSubmitting}
          />
        </Form>
      </Container>
    </BottomSheet>
  );
}

function Header(props: { midia: Midia; form: UseFormReturn<Midia, any> }) {
  const values = props.form.watch();
  const midia = React.useMemo(() => {
    return {
      ...props.midia,
      nome: values.nome || "Nome da mídia",
    } as Midia;
  }, [props.midia, values]);

  return (
    <ItemMidia.Container>
      <ItemMidia.Image midia={midia} />
      <ItemMidia.Content midia={midia} />
    </ItemMidia.Container>
  );
}

export default React.forwardRef(BottomSheetSalvarMidia);
