import React from "react";
import useSWR from "swr";
import { RouteProp, useRoute } from "@react-navigation/native";

import BottomSheet, { BottomSheetRef } from "@components/bottom-sheet";
import Button from "@components/button";
import Divider from "@components/divider";
import FieldText from "@components/field-text";
import FieldSelect from "@components/field-select";
import ModalCloseButton from "@components/modal/modal-close-button";
import TelaService from "@services/tela.service";
import PlaylistService from "@services/playlist.service";
import Tela from "../../../models/tela.model";
import { useService } from "@hooks/use-service";

import { Container, Row } from "./styles";
import { Text } from "@/global-styles";

import EditIconSvg from "@assets/vectors/edit.svg";
import EditIconActiveSvg from "@assets/vectors/edit-active.svg";

interface Props {
  onSuccess: (tela: Tela) => void;
  onClose: () => void;
}

interface Option {
  value: string;
  label: string;
}

function BottomSheetCriarTela(
  props: Props,
  ref: React.ForwardedRef<BottomSheetRef>
) {
  const sheetRef = ref as React.MutableRefObject<BottomSheetRef>;
  const telaService = useService(TelaService);
  const playlistService = useService(PlaylistService);

  const route = useRoute<RouteProp<RootTabParamList, "Telas">>();

  const [nome, setNome] = React.useState("");
  const [descricao, setDescricao] = React.useState("");
  const [playlist, setPlaylist] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<Option[]>([]);

  const fetcher = () => playlistService.listar();
  const { data: playlists } = useSWR("/playlists", fetcher);

  async function salvarTela() {
    setLoading(true);

    const tela = {
      nome: nome?.trim() || "",
      descricao: descricao?.trim() || "",
      playlist: playlist,
      token: route.params?.data || "",
    };

    try {
      const telaCriada = await telaService.criarTela(tela);
      props.onSuccess(telaCriada);
      sheetRef.current?.close();
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  }

  function onClose() {
    props.onClose();
    setNome("");
    setDescricao("");
    setPlaylist("");
  }

  React.useEffect(() => {
    if (!playlists?.length) {
      setOptions([]);
    } else {
      setOptions(
        playlists.map((playlist) => ({
          value: playlist._id,
          label: playlist.nome,
        }))
      );
    }
  }, [playlists]);

  return (
    <BottomSheet
      ref={ref}
      contentContainerStyle={{ padding: 24 }}
      onClose={onClose}
      header={{
        title: "Vincular a uma tela",
        right: <ModalCloseButton onPress={() => sheetRef.current?.close()} />,
      }}
    >
      <Divider />
      <Container>
        <Text size={16} style={{ marginBottom: 24 }}>
          Adicione as informações abaixo para vincular com essa tela.
        </Text>
        <Row>
          <FieldText
            label="Inserir Nome"
            icon={({ focused }) => (focused ? EditIconActiveSvg : EditIconSvg)}
            placeholder="Nome da tela"
            onChangeText={setNome}
            value={nome}
          />
        </Row>
        <Row>
          <FieldText
            label="Inserir Descricao"
            icon={({ focused }) => (focused ? EditIconActiveSvg : EditIconSvg)}
            placeholder="Descricao da tela"
            onChangeText={setDescricao}
            value={descricao}
          />
        </Row>
        <Row>
          <FieldSelect
            label="Playlist"
            onChange={setPlaylist}
            value={playlist}
            items={options}
          />
        </Row>
        <Button
          color="primary"
          text="Confirmar"
          disabled={!nome || !descricao || !playlist || loading}
          loading={loading}
          onPress={salvarTela}
        />
      </Container>
    </BottomSheet>
  );
}

export default React.forwardRef(BottomSheetCriarTela);
