import { useNavigation } from "@react-navigation/native";

import Midia from "@models/midia.model";
import { Image, ImageContainer } from "./styles";

interface Props {
  midia: Midia;
  progress?: number;
}

function ItemMidiaImage(props: Props) {
  const { midia } = props;
  const navigation = useNavigation<Navigation>();

  function handleVisualizar() {
    navigation.navigate("VisualizarMidia", {
      id: midia._id,
      nome: midia.nome,
      tipo: midia.tipo,
      uri: midia.link_midia || "",
    });
  }

  return (
    <ImageContainer onPress={handleVisualizar}>
      <Image source={{ uri: midia.link_miniatura || midia.link_midia }} />
    </ImageContainer>
  );
}

export default ItemMidiaImage;
